import React, { useContext } from 'react';
import { Dialog, DialogTitle, Button, DialogActions, DialogContent, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/core';
import { useHistory } from 'react-router';

import firebase from '../utils/firebase';
import { LoggedUserContext } from '../providers/LoggedUserProvider';


const useStyles = makeStyles({
    dialogTitle: {
        backgroundColor: '#373B53',
        padding: '24px 32px',
        color: '#ffffff',
        '& h2': {
            fontSize: '1.5rem',
        },
    },
    dialogFooter: {
        textAlign: 'right',
        padding: '24px 32px',
        backgroundColor: '#373B53',
    },
    button: {
        color: '#ffffff',
    }
});

export default function DeleteDialog({ open, setOpen, username }) {
    const [loggedUser] = useContext(LoggedUserContext);
    const history = useHistory();
    const classes = useStyles();

    const deleteUser = async () => {
        console.log('user', loggedUser);
        const db = firebase.firestore();
        await db.collection('users').doc(username).delete();

        if (username.indexOf('@') === -1) {
            await db.collection('notes').doc(username).delete();
            await db.collection('records').doc(username).delete();
            await db.collection('registrations').doc(username).delete();
        }

        history.push('/members');
    }

    return (
        <Dialog open={open} onClose={() => setOpen(false)}>
            <DialogTitle className={classes.dialogTitle}>Are you sure?</DialogTitle>
            <DialogContent>
                <Typography variant="body1" style={{margin: '1rem 0'}}>
                    When you delete a user, this action cannot be undone. Make sure you are ready to completely remove this user and their data.
                </Typography>
            </DialogContent>
            <DialogActions className={classes.dialogFooter}>
                <Button className={classes.button} onClick={() => setOpen(false)}>Cancel</Button>
                <Button variant="contained" color="primary" onClick={deleteUser}>Delete</Button>
            </DialogActions>
        </Dialog>        
    );
}
