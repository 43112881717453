import React, { useState, useContext, useEffect } from 'react';
import { Grid, FormControl, Button, Typography, Slider } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { DatePicker } from "@material-ui/pickers";
import moment from 'moment';

import { DataContext } from '../providers/DataProvider';
import Number from '../components/Number';


const useStyles = makeStyles({
    gridContainer: {},
    gridItem: {
        margin: '2rem 0',
        '&:first-child': {
            marginBottom: '0',
        },
    },
    control: {
        width: '100%'
    },
    text: {
        fontWeight: '500',
        margin: '1rem 0 0 0'
    },
    slider: {
        maxWidth: '256px',
    },
    sliderLabels: {
        fontSize: '0.85em',
        fontWeight: '600',
        marginTop: '12px'
    },
    submit: {
        paddingLeft: '2rem',
        paddingRight: '2rem'
    },
    track: {
        color: '#373B53',
        height: '12px',
        borderRadius: '15px'
    },
    rail: {
        color: '#373B53',
        height: '12px',
        borderRadius: '15px'
    },
    thumb: {
        color: '#1FCCCD',
        height: '20px',
        width: '20px',
    },
    bold: {
        color: '#1FCCCD',
    },
});

export default function Form({ setFormSubmitted = ()=>{} }) {
    const classes = useStyles();
    const [registration,] = useContext(DataContext);
    // const [doseError, setDoseError] = useState('You need more than zero doses');
    
    // Fields
    const [doses, setDoses] = useState(1);
    const [dateOpen, setDateOpen] = useState(false);
    const [date, setDate] = useState(moment());
    const [theType, setTheType] = useState('coffee');
    const [, setNumCoffees] = useState(1);
    const [, setNumTeas] = useState(0);
    const [durationDays, setDurationDays] = useState(1);
    // const [errors, setErrors] = useState({});

    // const submit = async () => {
    //     const email = cookies.users;
    //     setIsLoading(true);
    //     const db = firebase.firestore();
    //     let obj = {
    //         doses: parseInt(doses),
    //         updatedAt: moment().unix(),
    //         email: cookies.users,
    //         theType: theType,
    //         numCoffees: numCoffees,
    //         numTeas: numTeas,
    //         durationDays: durationDays,
    //     };

    //     if (!registration || (!registration.startedAt && !registration.createdAt)) {
    //         obj.startedAt = date.unix();
    //         obj.createdAt = moment().unix();
    //     }
    //     else {
    //         obj.startedAt = registration.startedAt;
    //         obj.createdAt = registration.createdAt;
    //     }

    //     await db.collection('registrations').doc(cookies.users).set(obj);
    //     setRegistration(obj);
        
    //     if (!daily) {
    //         let dailyArray = [];
    //         for (let i = 0; i < durationDays; i++) {
    //             let doseArray = {
    //                 doses: [],
    //             };
    //             for (let j = 0; j < doses; j++) {
    //                 doseArray.doses.push({});
    //             }
    //             dailyArray.push(doseArray);
    //         }

    //         const dailyObj = {
    //             daily: dailyArray
    //         };
    //         await db.collection('records').doc(cookies.users).set(dailyObj);
    //         setDaily(dailyObj);
    //     }
    //     else {
    //         const daysDiff = durationDays - registration.durationDays;
    //         console.log('DAILY DIFF', daysDiff);
    //         let _updatedDaily = daily.daily;
    //         for (let i = 0; i < Math.abs(daysDiff); i++) {
    //             let doseArray = {
    //                 doses: [],
    //             };
    //             for (let j = 0; j < doses; j++) {
    //                 doseArray.doses.push({});
    //             }
    //             _updatedDaily.push(doseArray);
    //         }
    //         console.log('UPDATED DAILY', _updatedDaily);

    //         const doseDiff = doses - registration.doses;
    //         console.log('UPDATE DAILY DIFF', doseDiff);
    //         const _daily = _updatedDaily.map((d) => {
    //             const _doses = d.doses;
    //             for (let i = 0; i < Math.abs(doseDiff); i++) {
    //                 if (doseDiff > 0) {
    //                     _doses.push({});
    //                 }
    //                 else {
    //                     _doses.pop();
    //                 }
    //             }
    //             return {
    //                 doses: _doses,
    //             };
    //         });
    //         console.log('NEW DAILY', _daily);
    //         const dailyObj = {
    //             daily: _daily,
    //         };
    //         setDaily(dailyObj);
    //         await db.collection('records').doc(cookies.users).set(dailyObj);
    //     }

    //     setVariant('success');
    //     setText('Settings saved!');
    //     history.push('/home');
    // };

    const allocateDoses = (_, value) => {
        setDoses(value);
        allocateTypes(value);
    };

    const allocateTypes = (num) => {
        if (theType === 'coffee') {
            setNumCoffees(num);
            setNumTeas(0);
        }
        else if (theType === 'tea') {
            setNumTeas(num);
            setNumCoffees(num);
        }
        else if (theType === 'both') {
            setNumCoffees(0);
            setNumTeas(0);
        }
    };

    // const disableSubmit = () => {
    //     return ((numCoffees + numTeas) !== doses);
    // };

    useEffect(() => {
        if (registration) {
            setDoses(registration.doses);
            setTheType(registration.theType);
            setNumCoffees(registration.numCoffees);
            setNumTeas(registration.numTeas);
            setDurationDays(registration.durationDays);
            setDate(moment.unix(registration.startedAt));
        }
    }, [registration, setDoses, setTheType, setNumCoffees, setNumTeas, setDurationDays, setDate]);

    return (
        <Grid className={classes.gridContainer} container>
            <Grid className={classes.gridItem} item xs={12}>
                <Typography variant="h1">
                    Settings
                </Typography>
            </Grid>
            <Grid className={classes.gridItem} item xs={12}>
                <FormControl component="fieldset" className={classes.control}>
                    <Typography variant="h6" className={classes.text}>
                        <Number value={1} /> Select your dosage:
                    </Typography>
                    <Slider 
                        step={1}
                        value={doses} 
                        min={1} 
                        max={6}
                        aria-labelledby="discrete-slider-always" 
                        onChange={allocateDoses}
                        className={classes.slider}
                        classes={{
                            track: classes.track,
                            rail: classes.rail,
                            thumb: classes.thumb
                        }}
                    />
                    <Typography variant="body1" className={classes.sliderLabels}>
                        {doses} time{doses > 1 ? 's' : ''} daily
                    </Typography>
                </FormControl>
            </Grid>
            {/*<Grid className={classes.gridItem} item xs={12}>
                <FormControl component="fieldset" className={classes.control}>
                    <Typography variant="h6" className={classes.text}>
                        <Number value={2} /> Select your product:
                    </Typography>
                    <RadioInput
                        name="theType"
                        setValue={(value) => {
                            if (value === 'coffee') {
                                setNumCoffees(doses);
                                setNumTeas(0);
                            }
                            else if (value === 'tea') {
                                setNumTeas(doses);
                                setNumCoffees(0);
                            }
                            setTheType(value);
                        }}
                        value={theType}
                        color="secondary"
                        options={[
                            {
                                value: 'coffee',
                                label: 'Coffee'
                            },
                            {
                                value: 'tea',
                                label: 'Tea'
                            },
                            {
                                value: 'both',
                                label: 'Both'
                            }
                        ]}
                    />
                </FormControl>
            </Grid>*/}
            {/*
                theType === 'both' ?
                (
                    <Grid className={classes.gridItem} item xs={12} style={{margin: '0'}}>
                        <FormControl component="fieldset" className={classes.control}>
                            <Grid container spacing={3}>
                                <Grid item xs={12} sm={3}>
                                    <Slider 
                                        step={1}
                                        value={numCoffees} 
                                        min={0} 
                                        max={10}
                                        aria-labelledby="discrete-slider-always" 
                                        onChange={(_, value) => setNumCoffees(value)}
                                        className={classes.slider}
                                        classes={{
                                            track: classes.track,
                                            rail: classes.rail,
                                            thumb: classes.thumb
                                        }}
                                    />
                                    <Typography variant="body1" className={classes.sliderLabels}>
                                        {numCoffees} coffee{numCoffees !== 1 ? 's' : ''} per day
                                    </Typography>
                                </Grid>
                                <Grid item xs={12} sm={3}>
                                    <Slider 
                                        step={1}
                                        value={numTeas} 
                                        min={0} 
                                        max={10}
                                        aria-labelledby="discrete-slider-always" 
                                        onChange={(_, value) => setNumTeas(value)}
                                        className={classes.slider}
                                        classes={{
                                            track: classes.track,
                                            rail: classes.rail,
                                            thumb: classes.thumb
                                        }}
                                    />
                                    <Typography variant="body1" className={classes.sliderLabels}>
                                        {numTeas} tea{numTeas !== 1 ? 's' : ''} per day
                                    </Typography>
                                </Grid>
                                <Grid item xs={12} style={{opacity: doses === (numCoffees + numTeas) ? '0' : '1'}}>
                                    <Typography variant="subtitle2">
                                        <b>This combination should add up to the dosage selected above.</b>
                                    </Typography>
                                </Grid>
                            </Grid>
                        </FormControl>
                    </Grid>
                ) : null
            */}
            {/*<Grid className={classes.gridItem} item xs={12}>
                <FormControl component="fieldset" className={classes.control}>
                    <Typography variant="h6" className={classes.text}>
                        <Number value={3} /> Select your trial length: 
                    </Typography>
                    <Slider 
                        step={1}
                        value={durationDays} 
                        min={1} 
                        max={30}
                        aria-labelledby="discrete-slider-always" 
                        onChange={(_, value) => setDurationDays(value)}
                        className={classes.slider}
                        classes={{
                            track: classes.track,
                            rail: classes.rail,
                            thumb: classes.thumb
                        }}
                    />
                    <Typography variant="body1" className={classes.sliderLabels}>
                        {durationDays} day{durationDays > 1 ? 's' : ''}
                    </Typography>
                </FormControl>
            </Grid>*/}
            <Grid className={classes.gridItem} item xs={12}>
                <FormControl component="fieldset" className={classes.control}>
                    <div>
                        <Typography variant="h6" className={classes.text} style={{marginRight: '1rem', verticalAlign: 'middle'}}>
                            <Number value={2} /> Select your start date:
                        </Typography>
                        {
                            registration ? 
                            null :
                            (
                                <Button style={{width: '256px', padding: '0.5rem 0', margin: '0 1rem 1rem 0'}} variant="contained" color="primary" onClick={() => setDateOpen(true)}>
                                    <Typography variant="subtitle1">
                                        <b>{moment(date).format('MMM DD, yyyy')}</b>
                                    </Typography>
                                </Button>
                            )
                        }
                        <Typography variant="subtitle2" style={{margin: '0.5rem 0'}}>
                            <b>Please note: The start date cannot be modified after {registration ? 'the' : 'this'} initial enrollment.</b>
                        </Typography>
                    </div>
                    <DatePicker
                        clearable
                        open={dateOpen}
                        onClose={() => setDateOpen(false)}
                        value={date}
                        placeholder="01/23/2020"
                        onChange={newDate => {
                            setDate(newDate);
                            console.log(newDate);
                        }}
                        format="MM/DD/yyyy"
                        TextFieldComponent={() => null}
                    />    
                </FormControl>
            </Grid>
            <Grid className={classes.gridItem} item xs={12}>
                <Typography className={classes.text} variant="body1" style={{border: '1px solid #1FCCCD', padding: '1rem', margin: '1rem 0 2rem 0'}}>
                    Your trial will begin on <b className={classes.bold}>{moment(date).format('MMMM DD, yyyy')}</b> and will end on <b className={classes.bold}>{moment(date).add((durationDays - 1), 'days').format('MMMM DD, yyyy')}</b>. You will take <b className={classes.bold}>{doses} dose{doses > 1 ? 's' : ''}</b> per day.
                </Typography>
            </Grid>
        </Grid>
    );
}
