import React, { useState, useContext, useEffect } from 'react';
import { Grid, Typography, TextField, Button, CircularProgress } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { useCookies } from 'react-cookie';
import { useHistory } from 'react-router-dom';

import { LoggedUserContext } from '../providers/LoggedUserProvider';
import { notEmpty } from '../utils/validations';


const useStyles = makeStyles({
    header: {
        fontWeight: '700',
        fontSize: '2rem'
    },
    label: {
        fontWeight: '400'
    },
    gridContainer: {
        textAlign: 'center'
    },
    gridItem: {
        margin: '1rem 0 3rem 0',
        padding: '0 1.5rem'
    },
    textField: {
        width: '100%'
    }
});

export default function SignIn() {
    const history = useHistory();
    const classes = useStyles();
    const [, setLoggedUser] = useContext(LoggedUserContext);
    const [isLoading, setIsLoading] = useState(false);
    const [user, setUser] = useState({
        username: '',
        password: ''
    });
    const [cookies, setCookies] = useCookies(['user']);

    useEffect(() => {
        console.log('COOKIES', cookies);
        if (cookies.users) {
            history.push('/home');
        }
    }, [cookies, history]);

    const signIn = async () => {
        setIsLoading(true);
        if (notEmpty(user.username) && notEmpty(user.password)) {
            try {
                const response = await fetch('https://us-east4-mighty-artemi.cloudfunctions.net/sign-in', {
                    method: "POST",
                    headers: {
                        "Content-Type": "application/json"
                    },
                    body: JSON.stringify(user)
                });

                console.log(response.body);
                setCookies('users', user.username, user);
                setIsLoading(false);
                setLoggedUser(user.username);

                const index = user.username.indexOf('@');
                if (index === -1) {
                    history.push('/home');
                }
                else if (index > -1) {
                    history.push('/members');
                }
            }
            catch (e) {
                console.log(e);
            }
        }
        else {
            console.log('not matches');
        }
    };

    return (
        <Grid className={classes.gridContainer} container>
            <Grid className={classes.gridItem} item xs={12}>
                <Typography variant="h1" className={classes.header}>
                    Sign In
                </Typography>
            </Grid>
            <Grid className={classes.gridItem} item md={6} sm={12}>
                <TextField
                    value={user.username}
                    onChange={(e) => setUser({
                        ...user,
                        username: e.target.value
                    })}
                    variant="outlined"
                    color="primary"
                    label="User ID"
                    className={classes.textField}
                />
            </Grid>
            <Grid className={classes.gridItem} item md={6} sm={12}>
                <TextField
                    value={user.password}
                    onChange={(e) => setUser({
                        ...user,
                        password: e.target.value
                    })}
                    type="password"
                    variant="outlined"
                    color="primary"
                    label="Password"
                    className={classes.textField}
                />
            </Grid>
            <Grid className={classes.gridItem} item xs={12}>
                <Button onClick={() => signIn()} variant="contained" color="primary">
                    { isLoading ? <CircularProgress size="1rem" color="secondary" /> : 'Continue'}
                </Button>
            </Grid>
        </Grid>
    );
}
