import React, { useEffect, useContext, useState } from 'react';
import { Dialog, DialogTitle, Grid, Typography, Button, TextField, FormControl, RadioGroup, Radio, FormControlLabel } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { DateTimePicker } from '@material-ui/pickers';
import moment from 'moment';

import { DataContext } from '../providers/DataProvider';


const useStyles = makeStyles({
    grid: {
        padding: '0 2rem 2rem 2rem',
    },
    text: {
        fontWeight: '500',
        margin: '1rem 0',
    },
    title: {
        padding: '2rem 2rem 1rem 2rem',
    },
});

export default function CustomDialog({ title, setOpen, open, record, theType, setTheType, date, setDate, notes, setNotes, onlyToday }) {
    const classes = useStyles();
    const [registration] = useContext(DataContext);
    const [disabled, setDisabled] = useState(false);

    useEffect(() => {
        if (!open) {
            setDisabled(false);
        }
    }, [open, setDisabled]);

    return (
        <Dialog onClose={() => setOpen(false)} open={open} aria-labelledby="custom-dialog-title">
            <DialogTitle className={classes.title} id="custom-dialog-title">{title}</DialogTitle>
            <Grid container className={classes.grid}>
                <Grid item xs={12}>
                    <FormControl>
                        <RadioGroup
                            aria-labelledby="the-type-radio-buttons-group-label"
                            value={theType}
                            onChange={(e) => setTheType(e.target.value)}
                            name="radio-buttons-group"
                        >
                            <FormControlLabel value="coffee" control={<Radio color="primary" />} label="Coffee" />
                            <FormControlLabel value="none" control={<Radio color="primary" />} label="None" />
                        </RadioGroup>
                    </FormControl>
                </Grid>
                <Grid item xs={12}>
                    <Typography variant="h6" className={classes.text}>
                        Select your date and time:
                    </Typography>
                    <DateTimePicker
                        clearable
                        value={date}
                        placeholder="01/23/2020"
                        onChange={newDate => setDate(newDate)}
                        // minDate={registration && registration.startedAt ? moment.unix(registration.startedAt).toDate() : moment().toDate()}
                        maxDate={onlyToday ? moment().toDate() : moment.unix(registration.startedAt).add(registration.durationDays - 1, 'days').toDate()}
                    /> 
                </Grid>
                <Grid item xs={12}>
                    <Typography variant="h6" className={classes.text}>
                        Notes:
                    </Typography>
                    <TextField
                        type="text"
                        value={notes}
                        onChange={(e) => setNotes(e.target.value)}
                        variant="outlined"
                        color="primary"
                    />
                </Grid>
                <Grid item xs={12} style={{textAlign: 'right', marginTop: '1rem'}}>
                    <Button color="primary" onClick={() => setOpen(false)} style={{marginRight: '1rem'}}>
                        <b>Close</b>
                    </Button>
                    <Button disabled={disabled} variant="contained" color="primary" onClick={() => {
                        setOpen(false);
                        setDisabled(true);
                        record();
                    }}>
                        <b>Record</b>
                    </Button>
                </Grid>
            </Grid>
        </Dialog>
    );
}
