import React, { createContext, useState, useCallback, useEffect } from 'react';
import { useCookies } from 'react-cookie';


export const LoggedUserContext = createContext([null, () => {}]);

export const LoggedUserProvider = ({ children }) => {
    const [cookies] = useCookies(['user']);
    const [loggedUser, _setLoggedUser] = useState('');

    const setLoggedUser = useCallback((user) => _setLoggedUser(user), [_setLoggedUser]);

    useEffect(() => {
      let isMounted = true;

      if (isMounted) {
        if (cookies.users) {
          setLoggedUser(cookies.users);
        }
        else {
          setLoggedUser('');
        }
      }

      return () => { 
        isMounted = false;
      };
    }, [cookies.users, setLoggedUser]);

    return (
        <LoggedUserContext.Provider value={[loggedUser, setLoggedUser]}>
            {children}
        </LoggedUserContext.Provider>
    );
};
