import React from 'react';


export default function CalendarAdd() {
    return (
        <svg width="22" height="22" viewBox="0 0 22 22" fill="none">
            <path fillRule="evenodd" clipRule="evenodd" d="M0 11C0 17.0751 4.92487 22 11 22C17.0751 22 22 17.0751 22 11C22 4.92487 17.0751 0 11 0C4.92487 0 0 4.92487 0 11ZM20 11C20 15.9706 15.9706 20 11 20C6.02944 20 2 15.9706 2 11C2 6.02944 6.02944 2 11 2C15.9706 2 20 6.02944 20 11ZM11.0321 18C7.67459 18 5.80643 16.2316 5.80643 13V12H16.1158L16.1434 12.9715C16.2358 16.2145 14.4003 18 11.0321 18ZM14.0875 14C13.8526 15.3955 12.9089 16 11.0321 16C9.15627 16 8.18179 15.3902 7.89677 14H14.0875ZM13 7H16V9H13V7ZM9 7H6V9H9V7Z" fill="#FFBB22"/>
        </svg>
    );
}
