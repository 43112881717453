import React from 'react';
import { Grid, Card, CardContent, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';


const useStyles = makeStyles({
    grid: {
        display: 'flex',
        flexWrap: 'wrap',
        flexFlow: 'column',
        justifyContent: 'center'
    },
    padded: {
        padding: '0 2rem',
    },
    content: {
        padding: '2rem',
    },
    icon: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
    },
    card: {
        height: '100%',
    },
});

export default function CustomCard({ title, subTitle, icon, backgroundColor }) {
    const classes = useStyles();

    return (
        <Card className={classes.card}>
            <CardContent style={{paddingBottom: '2rem'}} className={classes.content}>
                <Grid container>
                    <Grid item xs={4} className={`${classes.icon} ${classes.padded}`}>
                        <div style={{
                            backgroundColor: backgroundColor,
                            padding: '1rem',
                            borderRadius: '50%',
                            height: '85px',
                            flex: '0 0 85px',
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: 'center'
                        }}>
                            {icon}
                        </div>
                    </Grid>
                    <Grid item xs={8} className={`${classes.grid} ${classes.padded}`}>
                        <Typography variant="body1"><b>{title}</b></Typography>
                        <Typography variant="body1">{subTitle}</Typography>
                    </Grid>
                </Grid>
            </CardContent>
        </Card>
    );
}
