import React, { useState, useContext, useEffect } from 'react';
import { Grid, Typography, Button } from '@material-ui/core';
import MaterialTable from 'material-table';
import moment from 'moment';
import { useHistory } from 'react-router-dom';

import { DataContext } from '../providers/DataProvider';
import { LoggedUserContext } from '../providers/LoggedUserProvider';
import { NotificationContext } from '../providers/NotificationProvider';
import CustomDialog from '../components/CustomDialog';
import firebase from '../utils/firebase';


function EditComponent({ rowData, onChange, columnDef }) {
    const db = firebase.firestore();
    const [loggedUser] = useContext(LoggedUserContext);
    const [registration,, daily, setDaily] = useContext(DataContext);
    const [, setText] = useContext(NotificationContext);
    const [open, setOpen] = useState(false);
    const [theType, setTheType] = useState('coffee');
    const [date, setDate] = useState(new Date());
    const [notes, setNotes] = useState('');

    useEffect(() => {
        if (registration && rowData.tableData) {
            const diff = moment.unix(registration.startedAt).add(rowData.tableData.id, 'days');
            diff.set({
                hours: moment().hours(),
                minutes: moment().minutes(),
                seconds: 0,
            });
            console.log(diff);
            setDate(diff.toDate());
        }
    }, [registration, setDate, rowData]);

    useEffect(() => {
        if (rowData.tableData && registration && daily) {
            const dose = daily.daily[rowData.tableData.id].doses[parseInt(columnDef.field)];
            if (Object.keys(dose).length > 0) {
                setTheType(daily.daily[rowData.tableData.id].doses[parseInt(columnDef.field)].theType || 'coffee');
                setDate(new Date(moment.unix(dose.date)));
                setNotes(dose.notes);
            }
        }
    }, [rowData, registration, daily, columnDef]);

    const record = async () => {
        if (theType === 'coffee' || theType === 'tea' || theType === 'none') {
            if (registration && daily) {
                let _daily = daily;
                console.log('EXSITING TYPE', _daily.daily[rowData.tableData.id].doses[parseInt(columnDef.field)].theType);
                _daily.daily[rowData.tableData.id].doses[parseInt(columnDef.field)] = {
                    theType: _daily.daily[rowData.tableData.id].doses[parseInt(columnDef.field)].theType ? _daily.daily[rowData.tableData.id].doses[parseInt(columnDef.field)].theType : theType,
                    date: moment(date).unix(),
                    notes: notes,
                };

                await db.collection('records').doc(loggedUser).set(_daily);
                setDaily(_daily);
                setOpen(false);
                let theTypeDisplay = '';
                if (theType === 'coffee') theTypeDisplay = 'ArtemiCafe\u2122';
                if (theType === 'tea') theTypeDisplay = 'ArtemiTea\u2122';
                onChange(`${theTypeDisplay} on ${moment(date).format('MM/DD/YYYY')} at ${moment(date).format('hh:mma')}`);
                console.log(_daily);
                console.log(rowData);
                console.log('Recorded');
            }
        }
        else {
            setText('Please choose either Coffee or Tea.');
            setOpen(true);
        }
    };

    return (
        <>
            <Button variant="contained" color="primary" onClick={() => {
                console.log(columnDef);
                setOpen(true);
            }}>
                Edit
            </Button>
            <CustomDialog title="Record Daily Dose" open={open} setOpen={setOpen} record={record} date={date} setDate={setDate} theType={theType} setTheType={setTheType} notes={notes} setNotes={setNotes} />
        </>
    );
}

export default function History() {
    const [registration,, daily] = useContext(DataContext);
    const [data, setData] = useState([]);
    const [loggedUser] = useContext(LoggedUserContext);
    const db = firebase.firestore();
    const history = useHistory();
    const [columns] = useState(() => {
        let c = [
            {
                title: 'Day',
                field: 'day',
                editable: 'never',
                cellStyle: { paddingLeft: '24px', paddingRight: '24px' },
                headerStyle: { paddingLeft: '24px', paddingRight: '24px' }
            }
        ];
        for (let i = 0; i < registration.doses; i++) {
            c.push({
                title: `Dose ${i + 1}`,
                field: `${i}`,
                editComponent: (props) => (<EditComponent {...props} />),
            });
        }
        return c;
    });

    useEffect(() => {
        if (registration && daily && daily.daily) {
            const resolveData = daily.daily.map((d, j) => {
                let _data = {};
                for (let i = 0; i < d.doses.length; i++) {
                    let theTypeDisplay = '';
                    if (d.doses[i].theType === 'coffee') theTypeDisplay = 'ArtemiCafe\u2122';
                    if (d.doses[i].theType === 'tea') theTypeDisplay = 'ArtemiTea\u2122';
                    _data['day'] = moment.unix(registration.startedAt).add(j, 'days').format('MMMM DD, yyyy');
                    _data[`${i}`] = Object.keys(d.doses[i]).length > 0 ? d.doses[i].theType === 'none' ? 'No dose taken.' : `${theTypeDisplay} on ${moment.unix(d.doses[i].date).format('MM/DD/YYYY')} at ${moment.unix(d.doses[i].date).format('hh:mma')}` : '';
                }
                return _data;
            });
            setData(resolveData);
        }
    }, [daily, setData, registration]);

    useEffect(() => {
        if (loggedUser !== '') {
            const _getUser = async () => {
                const userRef = db.collection('users').doc(loggedUser);
                const userDoc = await userRef.get();
                if (!userDoc.exists) {
                    console.log('EMPTY USER');
                }
                else {
                    const userData = userDoc.data();
                    console.log('USER DATA', userData);

                    if (!userData.hasPassword) {
                        history.push('/reset-password');
                        return;
                    }
                }
            };
            _getUser();
        }
    }, [db, loggedUser, history]);

    return (
        <Grid container>
            <Grid item xs={12}>
                <Typography variant="h1" style={{fontWeight: '600'}}>
                    History
                </Typography>
            </Grid>
            <Grid item xs={12}>
                <MaterialTable
                    title={registration ? `Today is ${moment().format('MMMM DD, yyyy')}` : ''}
                    columns={columns}
                    data={data}
                    editable={{
                        onRowUpdate: moment().startOf('day').diff(moment.unix(registration.startedAt).startOf('day'), 'days') <= (registration.durationDays + 7) ? (newData, oldData) => 
                            new Promise((resolve) => {
                                console.log(oldData);
                                const index = oldData.tableData.id;
                                let _data = [...data];
                                _data[index] = newData;
                                setData([..._data]);
                                resolve();
                            }) : null,
                    }}
                    options={{
                        search: false,
                        pageSize: 10,
                        pageSizeOptions: [],
                        showFirstLastPageButtons: false
                    }}
                    style={{
                        margin: '2rem 0 0 0',
                        borderRadius: '4px',
                    }}
                    components={{
                        Toolbar: (props) => {
                            return (
                                <div style={{display: 'flex', alignItems: 'center', justifyContent: 'space-between', padding: '24px 0', backgroundColor: '#373B53', borderTopRightRadius: '4px', borderTopLeftRadius: '4px'}}>
                                    <Typography gutterBottom variant="h6" component="div" style={{paddingLeft: '24px', paddingRight: '24px', margin: '0', fontWeight: '700', color: '#ffffff'}}>
                                        {props.title}
                                    </Typography>
                                </div>
                            )
                        }
                    }}
                />
            </Grid>
        </Grid>
    );
}
