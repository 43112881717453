export default (arr, key) => {
    let counter = 0;
    arr.forEach((x) => {
        x[key].forEach((y) => {
            if (Object.keys(y).length > 0) {
                counter += 1;
            }
        });
    });
    return counter;
};
