import React from 'react';
import { CssBaseline, AppBar, Drawer, Toolbar, Container } from '@material-ui/core';
import { createMuiTheme, ThemeProvider, makeStyles } from '@material-ui/core/styles';
import withWidth, { isWidthUp, isWidthDown } from '@material-ui/core/withWidth';
import { MuiPickersUtilsProvider } from '@material-ui/pickers';
import MomentUtils from '@date-io/moment';
import { BrowserRouter, Link } from 'react-router-dom';
import { CookiesProvider } from "react-cookie";

import LogoA from './svgs/LogoA';
import Sidebar from './components/Sidebar';
import { LoggedUserProvider } from './providers/LoggedUserProvider';
import { NotificationProvider } from './providers/NotificationProvider';
import { DataProvider } from './providers/DataProvider';
import Routes from './Routes';
import Nav from './Nav';


const drawerWidth = 100;
const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    height: '100%',
  },
  appBar: {
    width: `calc(100% - ${drawerWidth}px)`,
    marginLeft: drawerWidth,
    [theme.breakpoints.down('sm')]: {
      width: '100%',
    },
  },
  drawer: {
    width: drawerWidth,
    flexShrink: 0,
  },
  drawerPaper: {
    width: drawerWidth,
    backgroundColor: '#373B53',
    border: 'none',
  },
  mobileDrawer: {
    height: drawerWidth,
    width: '100%',
    flex: '0',
  },
  mobileDrawerPaper: {
    height: drawerWidth,
    backgroundColor: '#373B53',
    border: 'none',
    width: '100%',
    top: 'initial',
    bottom: '0',
  },
  // necessary for content to be below app bar
  toolbar: theme.mixins.toolbar,
  toolbarLogo: {
    backgroundColor: '#1FCCCD',
  },
  content: {
    flexGrow: 1,
    backgroundColor: theme.palette.background.default,
    padding: `${drawerWidth}px 5rem`,
    width: `calc(100% - ${drawerWidth}px)`,
    [theme.breakpoints.down('md')]: {
      padding: `${drawerWidth}px 1rem`,
    },
  },
  contentContainer: {
    padding: '3rem 0',
    [theme.breakpoints.down('sm')]: {
      padding: '1rem 0',
    },
  },
}));

function App({ width }) {
  const theme = createMuiTheme({
    palette: {
      primary: {
        main: '#1FCCCD',
        contrastText: '#ffffff'
      },
      secondary: {
        main: '#ffffff'
      },
      third: {
        main: ''
      }
    },
    typography: {
      button: {
        fontWeight: 'bold'
      },
      h1: {
        fontSize: '2em',
        fontWeight: '500',
        color: '#000000',
        letterSpacing: '1.15px'
      },
      h5: {
        fontWeight: 'bold'
      },
      h6: {
        fontWeight: 'bold'
      }
    }
  });
  const classes = useStyles();

  return (
    <ThemeProvider theme={theme}>
      <CssBaseline />
      <BrowserRouter>
        <CookiesProvider>
          <LoggedUserProvider>
            <NotificationProvider>
              <DataProvider>
                <MuiPickersUtilsProvider utils={MomentUtils}>
                  <div className={classes.root}>
                    <AppBar position="fixed" className={classes.appBar} color="secondary">
                      <Toolbar>
                        <Nav />
                      </Toolbar>
                    </AppBar>
                    {
                      isWidthUp('md', width) && (
                        <Drawer
                          className={classes.drawer}
                          variant="permanent"
                          classes={{
                            paper: classes.drawerPaper
                          }}
                        >
                          <div className={`${classes.toolbar} ${classes.toolbarLogo}`} style={{textAlign: 'center'}}>
                            <Link to="/">
                              <LogoA style={{maxWidth: '75%'}} />
                            </Link>
                          </div>
                          <Sidebar />
                        </Drawer>
                      )
                    }
                    <main className={classes.content}>
                      <Container maxWidth="lg" className={classes.contentContainer}>
                        <Routes />
                      </Container>
                    </main>
                    {
                      isWidthDown('sm', width) && (
                        <Drawer
                          className={classes.mobileDrawer}
                          variant="permanent"
                          classes={{
                            paper: classes.mobileDrawerPaper
                          }}
                        >
                          <Sidebar />
                        </Drawer>
                      )
                    }
                  </div>
                </MuiPickersUtilsProvider>
              </DataProvider>
            </NotificationProvider>
          </LoggedUserProvider>
        </CookiesProvider>
      </BrowserRouter>
    </ThemeProvider>
  );
}

export default withWidth()(App);
