import React from 'react';


export default function CalendarAdd() {
    return (
        <svg width="21" height="21" viewBox="0 0 21 21" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path fillRule="evenodd" clipRule="evenodd" d="M4 4H6V3H14V4H16V3H18V6H2V3H4V4ZM2 8V18H12V20H2C0.89543 20 0 19.1046 0 18V3C0 1.89543 0.89543 1 2 1H4V0H6V1H14V0H16V1H18C19.1046 1 20 1.89543 20 3V12H18V8H2ZM19 17V15H17V17H15V19H17V21H19V19H21V17H19Z" fill="#1FCCCD"/>
        </svg>
    );
}
