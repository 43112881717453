import React, { useContext, useState } from 'react';
import { Button, Typography, Grid, Container, Menu, MenuItem } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { ArrowDropDown } from '@material-ui/icons';
import { Link, useHistory } from 'react-router-dom';
import { useCookies } from 'react-cookie';

import Logo from './svgs/Logo';
import { LoggedUserContext } from './providers/LoggedUserProvider';
import markeyImg from './images/markey-logo.png';

const useStyles = makeStyles((theme) => ({
    button: {
        backgroundColor: '#ffffff',
        color: '#1FCCCD',
        '&:hover': {
            color: '#ffffff'
        }
    },
    logo: {
        display: 'flex',
        alignItems: 'center',
    },
    text: {
        fontWeight: '500',
        padding: '0 0 0 1rem'
    },
    item: {
        [theme.breakpoints.down('sm')]: {
            flexBasis: '50%',
            justifyContent: 'flex-end',
        },
    },
    image: {
        display: 'inline-block',
        maxHeight: '40px',
    },
}));

export default function Nav() {
    const classes = useStyles();
    const [loggedUser, setLoggedUser] = useContext(LoggedUserContext);
    const [menuEl, setMenuEl] = useState(null);
    const [,, removeCookies] = useCookies(['user']);
    const history = useHistory();


    const signOutFirebaseUser = () => {
        removeCookies('users');
        setLoggedUser('');
        // setRegistration({});
        // setDaily({});
        setMenuEl(null);
        history.push('/');
    };

    const openMenu = (event) => {
        setMenuEl(event.currentTarget);
    };
    const menuClose = () => setMenuEl(null);

    return (    
        <Container style={{padding: '0'}}>
            <Grid container style={{padding: '2rem 0', justifyContent: 'space-between'}}>    
                <Grid item className={`${classes.logo} ${classes.item}`} style={{justifyContent: 'flex-start'}}>
                    <img className={classes.image} src={markeyImg} alt="UK Markey Cancer Center" />
                    <Link to="/">
                        <Logo />
                    </Link>
                </Grid>
                {
                   loggedUser && loggedUser !== '' ?
                    (
                        <Grid item className={classes.item} style={{textAlign: 'right'}}>
                            <Button aria-controls="user-menu" aria-haspopup="true" onClick={openMenu}>
                                <Typography variant="body1" className={classes.text} style={{display: 'inline-block', textTransform: 'initial'}}>
                                    {loggedUser} <ArrowDropDown style={{verticalAlign: 'middle'}} />
                                </Typography>
                            </Button>
                            <Menu
                                id="user-menu"
                                keepMounted
                                anchorEl={menuEl}
                                getContentAnchorEl={null} 
                                open={Boolean(menuEl)}
                                onClose={menuClose}
                                anchorOrigin={{horizontal: 'center', vertical: 'bottom'}}
                            >
                                <MenuItem onClick={signOutFirebaseUser}>Sign Out</MenuItem>
                            </Menu>
                        </Grid>
                    ) : null
                }
            </Grid>
        </Container>
    );
}
