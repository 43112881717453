import React from 'react';


export default function Sun(props) {
    return (
        <svg {...props} width="49" height="49" viewBox="0 0 49 49" fill="none">
            <g clipPath="url(#clip0)">
                <path d="M24.4846 42.8482C14.3504 42.8482 6.1145 34.6122 6.1145 24.4781C6.1145 14.3439 14.3504 6.10791 24.4846 6.10791C34.6188 6.10791 42.8548 14.3439 42.8548 24.4781C42.8548 34.6122 34.6188 42.8482 24.4846 42.8482ZM24.4846 9.1696C16.0344 9.1696 9.17619 16.0278 9.17619 24.4781C9.17619 32.9283 16.0344 39.7865 24.4846 39.7865C32.9349 39.7865 39.7931 32.9283 39.7931 24.4781C39.7931 16.0278 32.9349 9.1696 24.4846 9.1696Z" fill="#FFBB22"/>
                <path d="M24.4847 9.16969C23.6274 9.16969 22.9539 8.49612 22.9539 7.63884V1.51546C22.9539 0.658191 23.6274 -0.0153809 24.4847 -0.0153809C25.342 -0.0153809 26.0155 0.658191 26.0155 1.51546V7.63884C26.0155 8.49612 25.342 9.16969 24.4847 9.16969Z" fill="#FFBB22"/>
                <path d="M24.4847 9.16969C23.6274 9.16969 22.9539 8.49612 22.9539 7.63884V1.51546C22.9539 0.658191 23.6274 -0.0153809 24.4847 -0.0153809C25.342 -0.0153809 26.0155 0.658191 26.0155 1.51546V7.63884C26.0155 8.49612 25.342 9.16969 24.4847 9.16969Z" fill="#FFBB22"/>
                <path d="M24.4847 48.9716C23.6274 48.9716 22.9539 48.298 22.9539 47.4407V41.3173C22.9539 40.4601 23.6274 39.7865 24.4847 39.7865C25.342 39.7865 26.0155 40.4601 26.0155 41.3173V47.4407C26.0155 48.298 25.342 48.9716 24.4847 48.9716Z" fill="#FFBB22"/>
                <path d="M47.4474 26.0088H41.3241C40.4668 26.0088 39.7932 25.3353 39.7932 24.478C39.7932 23.6207 40.4668 22.9471 41.3241 22.9471H47.4474C48.3047 22.9471 48.9783 23.6207 48.9783 24.478C48.9783 25.3353 48.3047 26.0088 47.4474 26.0088Z" fill="#FFBB22"/>
                <path d="M47.4474 26.0088H41.3241C40.4668 26.0088 39.7932 25.3353 39.7932 24.478C39.7932 23.6207 40.4668 22.9471 41.3241 22.9471H47.4474C48.3047 22.9471 48.9783 23.6207 48.9783 24.478C48.9783 25.3353 48.3047 26.0088 47.4474 26.0088Z" fill="#FFBB22"/>
                <path d="M7.64519 26.0088H1.52181C0.664539 26.0088 -0.0090332 25.3353 -0.0090332 24.478C-0.0090332 23.6207 0.664539 22.9471 1.52181 22.9471H7.64519C8.50247 22.9471 9.17604 23.6207 9.17604 24.478C9.17604 25.3353 8.50247 26.0088 7.64519 26.0088Z" fill="#FFBB22"/>
                <path d="M40.7423 42.2667C40.3443 42.2667 39.9463 42.1135 39.6708 41.8073L35.3538 37.4902C34.7721 36.8779 34.7721 35.9289 35.3538 35.3166C35.9661 34.7042 36.9152 34.7042 37.5276 35.3166L41.8446 39.6336C42.4263 40.246 42.4263 41.195 41.8446 41.8073C41.5078 42.1135 41.1097 42.2667 40.7423 42.2667Z" fill="#FFBB22"/>
                <path d="M40.7423 42.2667C40.3443 42.2667 39.9463 42.1135 39.6708 41.8073L35.3538 37.4902C34.7721 36.8779 34.7721 35.9289 35.3538 35.3166C35.9661 34.7042 36.9152 34.7042 37.5276 35.3166L41.8446 39.6336C42.4263 40.246 42.4263 41.195 41.8446 41.8073C41.5078 42.1135 41.1097 42.2667 40.7423 42.2667Z" fill="#FFBB22"/>
                <path d="M12.5744 14.1296C12.1764 14.1296 11.7784 13.9764 11.5028 13.6702L7.18582 9.35352C6.57348 8.74118 6.57348 7.79181 7.18582 7.17947C7.79815 6.56713 8.74728 6.56713 9.35962 7.17947L13.6766 11.4966C14.2889 12.1089 14.2889 13.0579 13.6766 13.6702C13.3704 13.9764 12.9724 14.1296 12.5744 14.1296Z" fill="#FFBB22"/>
                <path d="M8.25741 42.2667C7.85939 42.2667 7.46137 42.1135 7.18582 41.8073C6.57348 41.195 6.57348 40.246 7.18582 39.6336L11.5028 35.3166C12.1151 34.7042 13.0643 34.7042 13.6766 35.3166C14.2889 35.9289 14.2889 36.8779 13.6766 37.4902L9.35962 41.8073C9.05345 42.1135 8.65543 42.2667 8.25741 42.2667Z" fill="#FFBB22"/>
                <path d="M8.25741 42.2667C7.85939 42.2667 7.46137 42.1135 7.18582 41.8073C6.57348 41.195 6.57348 40.246 7.18582 39.6336L11.5028 35.3166C12.1151 34.7042 13.0643 34.7042 13.6766 35.3166C14.2889 35.9289 14.2889 36.8779 13.6766 37.4902L9.35962 41.8073C9.05345 42.1135 8.65543 42.2667 8.25741 42.2667Z" fill="#FFBB22"/>
                <path d="M36.3944 14.1294C35.9964 14.1294 35.5984 13.9762 35.3228 13.6701C34.7105 13.0883 34.7411 12.1087 35.3228 11.4964L39.6398 7.17929C40.2522 6.59757 41.2013 6.56695 41.8136 7.17929C42.426 7.76101 42.3954 8.74101 41.8136 9.35334L37.4966 13.6701C37.1905 13.9762 36.7925 14.1294 36.3944 14.1294Z" fill="#FFBB22"/>
            </g>
            <defs>
                <clipPath id="clip0">
                    <rect width="49" height="49" fill="white"/>
                </clipPath>
            </defs>
        </svg>
    );
}
