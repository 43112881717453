import React from 'react';


export default function UpTrend(props) {
    return (
        <svg width="41" height="36" viewBox="0 0 41 36">
            <path d="M30.2552 7.77423C32.3533 7.77423 34.0542 6.03391 34.0542 3.88712C34.0542 1.74032 32.3533 0 30.2552 0C28.157 0 26.4561 1.74032 26.4561 3.88712C26.4561 6.03391 28.157 7.77423 30.2552 7.77423Z" fill="#1FCCCD"/>
            <path d="M40.141 1.45773C39.3639 0.971839 38.3278 1.28104 37.8097 2.03196L34.1401 7.20006C33.7084 7.81846 33.1904 8.34853 32.5428 8.7019C30.341 9.89454 27.8371 9.18779 26.4988 7.33258L22.7428 2.03196C22.2248 1.28104 21.1887 0.971839 20.4116 1.45773C19.505 1.98779 19.2891 3.18043 19.8935 4.06386L23.6926 9.45282C24.3402 10.3363 25.1173 11.0872 25.9807 11.6172C26.3693 11.8823 26.5851 12.324 26.5851 12.7657V20.6283L24.2107 33.4381C24.038 34.3215 24.3834 35.2491 25.1173 35.6909C25.4626 35.9117 25.808 36.0001 26.1534 36.0001C27.1032 36.0001 27.9234 35.3375 28.0961 34.3657L30.2979 22.5277L32.4564 34.3657C32.6291 35.3375 33.4494 36.0001 34.3992 36.0001C34.7445 36.0001 35.0899 35.9117 35.4353 35.6909C36.1692 35.2491 36.5146 34.3215 36.3419 33.4381L33.9674 20.6283V12.7215C33.9674 12.2356 34.1833 11.7939 34.5718 11.5731C35.4353 10.9988 36.2555 10.2921 36.8599 9.40865L40.659 4.01969C41.2634 3.18043 41.0476 1.98779 40.141 1.45773Z" fill="#1FCCCD"/>
            <path d="M17.6922 11.6171C17.2173 10.9987 16.3107 10.9987 15.8358 11.6171L11.4323 17.4478C10.8279 18.2429 11.3892 19.3914 12.3821 19.3914H14.3248V35.1165C14.3248 35.5141 14.6702 35.8674 15.0587 35.8674H18.5556C18.9442 35.8674 19.2896 35.5141 19.2896 35.1165V19.3914H21.2323C22.2252 19.3914 22.7864 18.2429 22.182 17.4478L17.6922 11.6171Z" fill="#1FCCCD"/>
            <path d="M6.51057 19.2588C6.03568 18.6404 5.12908 18.6404 4.65419 19.2588L0.250694 25.0895C-0.353707 25.8846 0.207523 27.0331 1.20047 27.0331H3.14319V35.1607C3.14319 35.5582 3.48856 35.9116 3.8771 35.9116H7.374C7.76254 35.9116 8.10791 35.5582 8.10791 35.1607V27.0331H10.0506C11.0436 27.0331 11.6048 25.8846 11.0004 25.0895L6.51057 19.2588Z" fill="#1FCCCD"/>
        </svg>
    );
}
