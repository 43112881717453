import React, { useState, useContext } from 'react';
import { Grid, Typography, TextField, Button, CircularProgress } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { useHistory } from 'react-router-dom';

import { notEmpty } from '../utils/validations';
import { LoggedUserContext } from '../providers/LoggedUserProvider';


const useStyles = makeStyles({
    header: {
        fontWeight: '700',
        fontSize: '2rem'
    },
    label: {
        fontWeight: '400'
    },
    gridContainer: {
        textAlign: 'center'
    },
    gridItem: {
        margin: '1rem 0 3rem 0',
        padding: '0 1.5rem'
    },
    textField: {
        width: '100%'
    }
});

export default function ResetPassword() {
    const [user, setUser] = useState({
        password: '',
        passwordConfirmation: ''
    });
    const [isLoading, setIsLoading] = useState(false);
    const classes = useStyles();
    const history = useHistory();
    const [loggedUser] = useContext(LoggedUserContext);

    const resetPw = async () => {
        setIsLoading(true);

        if (loggedUser !== '' && notEmpty(user.password)) {
            try {
                const response = await fetch('https://us-east4-mighty-artemi.cloudfunctions.net/reset-password', {
                    method: "POST",
                    headers: {
                        "Content-Type": "application/json"
                    },
                    body: JSON.stringify({
                        password: user.password,
                        password_confirmation: user.passwordConfirmation,
                        username: loggedUser
                    })
                });

                console.log(response.body);
                setIsLoading(false);
                history.push('/home');
            }
            catch (e) {
                console.log(e);
            }
        }
    }

    return (
        <Grid className={classes.gridContainer} container>
            <Grid className={classes.gridItem} item xs={12}>
                <Typography variant="h1" className={classes.header}>
                    Reset Password
                </Typography>
            </Grid>
            <Grid className={classes.gridItem} item md={6} sm={12}>
                <TextField
                    error={user.password !== user.passwordConfirmation}
                    value={user.password}
                    onChange={(e) => setUser({
                        ...user,
                        password: e.target.value
                    })}
                    type="password"
                    variant="outlined"
                    color="primary"
                    label="Password"
                    className={classes.textField}
                />
            </Grid>
            <Grid className={classes.gridItem} item md={6} sm={12}>
                <TextField
                    error={user.password !== user.passwordConfirmation}
                    helperText={user.password !== user.passwordConfirmation ? 'Must match password.' : ''}
                    value={user.passwordConfirmation}
                    onChange={(e) => setUser({
                        ...user,
                        passwordConfirmation: e.target.value
                    })}
                    type="password"
                    variant="outlined"
                    color="primary"
                    label="Password Confirmation"
                    className={classes.textField}
                />
            </Grid>
            <Grid className={classes.gridItem} item xs={12}>
                <Button variant="contained" color="primary" onClick={() => resetPw()}>
                    { isLoading ? <CircularProgress color="secondary" /> : 'Confirm Reset'}
                </Button>
            </Grid>
        </Grid>
    );
}
