import React from 'react';
import { Grid } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';

import SignIn from '../components/SignIn';


const useStyles = makeStyles({
    container: {
        padding: '1rem',
    },
    gridItem: {
        margin: '2rem 0'
    },
    text: {
        margin: '0.5rem 0'
    }
});

export default function Authenticate() {
    const classes = useStyles();
    // const [isSignUp, setSignUp] = useState(false);

    return (
        <>
            {
                <Grid container className={classes.container}>
                    <Grid className={classes.gridItem} item xs={12}>
                        <SignIn />
                    </Grid>
                </Grid>
            }
        </>
    );
}
