import React, { useState, useEffect, useContext } from 'react';
import { Dialog, DialogTitle, Grid, TextField, Button, CircularProgress, DialogContent, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/core';

import firebase from '../utils/firebase';
import { NotificationContext } from '../providers/NotificationProvider';


const useStyles = makeStyles({
    textFieldContainer: {
        maxWidth: '324px'
    },
	textField: {
		margin: '0 0 1.5rem 0',
        width: '100%'
	}
});

export default function UserDialog({ open, setOpen, setUsers, username }) {
	const classes = useStyles();
	const db = firebase.firestore();
	const [, setText, setVariant] = useContext(NotificationContext);
	const [isLoading, setIsLoading] = useState(false);
	const [user, setUser] = useState({
		email: '',
		username: '',
		password: '',
		password_confirmation: '',
		role: 'User'
	});

	useEffect(() => {
		if (username) {
			setUser((oldUser) => ({
				...oldUser,
				username: username,
				email: username
			}));
		}
		else {

		}
	}, [username, setUser]);

	const createUser = (e) => {
		setIsLoading(true);
		fetch('https://us-east4-mighty-artemi.cloudfunctions.net/sign-up', {
            method: "POST",
            headers: {
                "Content-Type": "application/json"
            },
            body: JSON.stringify(user)
        }).then((response) => {
        	console.log(response.text());
            setIsLoading(false);
            setUsers((oldUsers) => ([...oldUsers, user]));
            setUser({
            	email: '',
				username: '',
				password: '',
				password_confirmation: '',
				role: 'User'
            });
            setOpen(false);
			setVariant('success');
			setText('User created!');
            // history.push('/members');
        }, (error) => console.log(error.message));
	};

	const updateUser = async () => {
		setIsLoading(true);

		const userRef = db.collection('users').doc(username);
		const userDoc = await userRef.get();
		if (!userDoc) {
			console.log('Could not find user.');
		}
		else {
			const userData = userDoc.data();
			let obj = {
				username: username,
				email: userData.email,
				password: user.password,
				password_confirmation: user.password_confirmation,
				role: userData.role
			};

			fetch('https://us-east4-mighty-artemi.cloudfunctions.net/sign-up', {
			    method: "POST",
			    headers: {
			    	"Content-Type": "application/json"
			    },
			    body: JSON.stringify(obj)
			}).then((response) => {
				console.log(response.text());
			    setIsLoading(false);
			    setUser((oldUser) => ({
					...oldUser,
					password: '',
					password_confirmation: '',
					role: 'User'
			    }));
			    setOpen(false);
				setVariant('success');
				setText('User updated!');
			    // history.push('/members');
			}, (error) => console.log(error.message));
			
			console.log(userData, obj);
		}
	};

	return (
		<Dialog open={open} onClose={() => setOpen(false)}>
			<DialogTitle style={{paddingTop: '32px'}}>
                Add Admin
            </DialogTitle>
			<DialogContent style={{padding: '16px 24px 24px 24px'}}>
				<Grid container>
					<Grid item xs={12}>
                        <div className={classes.textFieldContainer}>
                            <TextField 
                                name="text"
                                variant="outlined"
                                color="primary"
                                value={user.email}
                                onChange={(e) => {
                                    e.persist();
                                    setUser((oldUser) => ({
                                        ...oldUser,
                                        email: e.target.value,
                                        username: e.target.value
                                    }))
                                }}
                                disabled={username ? true : false}
                                className={classes.textField}
                                label="Email"
                            />
                        </div>
					</Grid>
					<Grid item xs={12}>
                        <div className={classes.textFieldContainer}>
                            <TextField 
                                name="password"
                                label="Password"
                                variant="outlined"
                                color="primary"
                                value={user.password}
                                onChange={(e) => {
                                    e.persist();
                                    setUser((oldUser) => ({
                                        ...oldUser,
                                        password: e.target.value
                                    }))
                                }}
                                className={classes.textField}
                                type="password"
                            />
                        </div>
					</Grid>
					<Grid item xs={12}>
                        <div className={classes.textFieldContainer}>
                            <TextField 
                                name="text"
                                variant="outlined"
                                label="Password Confirmation"
                                color="primary"
                                value={user.password_confirmation}
                                onChange={(e) => {
                                    e.persist();
                                    setUser((oldUser) => ({
                                        ...oldUser,
                                        password_confirmation: e.target.value
                                    }))
                                }}
                                className={classes.textField}
                                type="password"
                                error={user.password === user.password_confirmation ? false : true}
                                helperText={user.password === user.password_confirmation ? null : 'Confirmation must match password'}
                            />
                        </div>
					</Grid>
                    <Grid item xs={12}>
                        <Typography variant="body2" style={{paddingTop: '16px'}}>
                            Please note: An admin has access to view, create, edit, and remove all current and previous trial participants.
                        </Typography>
                    </Grid>
				</Grid>

				<div style={{textAlign: 'right', paddingTop: '24px'}}>
                    <Button onClick={() => setOpen(false)} variant="contained" color="secondary" style={{marginRight: '1rem'}}>Close</Button>
                    <Button variant="contained" color="primary" onClick={username ? updateUser : createUser}>{ isLoading ? <CircularProgress color="secondary" size="1.5rem" /> : username ? 'Update' : 'Create' }</Button>
				</div>
			</DialogContent>
		</Dialog>
	);
}
