import React, { createContext, useState, useCallback, useEffect } from 'react';
import { Snackbar } from '@material-ui/core';
import { Alert } from '@material-ui/lab';


export const NotificationContext = createContext([null, () => {}]);

export const NotificationProvider = ({ children }) => {
    const [text, _setText] = useState('');
    const [open, _setOpen] = useState(false);
    const [variant, _setVariant] = useState('error');

    const setText = useCallback((t) => _setText(t), [_setText]);
    const setOpen = useCallback(() => _setOpen(true), [_setOpen]);
    const closeSnackbar = useCallback(() => {
        console.log('closing');
        _setOpen(false);
        _setText('');
    }, [_setOpen]);
    const setVariant = useCallback((value) => _setVariant(value), [_setVariant]);

    useEffect(() => {
        if (text !== '') {
            setOpen(true);
        }
    }, [text, setOpen]);

    return (
        <NotificationContext.Provider value={[text, setText, setVariant]}>
            {children}
            <Snackbar anchorOrigin={{vertical: 'top', horizontal: 'center'}} open={open} autoHideDuration={5000} onClose={closeSnackbar}>
                <Alert severity={variant}>{text}</Alert>
            </Snackbar>
        </NotificationContext.Provider>
    );
};
