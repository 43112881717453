import React, { useState, useContext, useEffect } from 'react';
import MaterialTable from 'material-table';
import { Grid, Button, CircularProgress, Container, Typography } from '@material-ui/core';
import { Link, useHistory } from 'react-router-dom';
import { makeStyles } from '@material-ui/styles';
import { Redirect } from 'react-router-dom';

import firebase from '../utils/firebase';
import UserDialog from '../components/UserDialog';
import ParDialog from '../components/ParDialog';
import { LoggedUserContext } from '../providers/LoggedUserProvider';


const useStyles = makeStyles({
	buttonWrapper: {
		margin: '1rem 0'
	},
	tableWrapper: {
		margin: '2rem 0'
	}
});

export default function Members() {
	const db = firebase.firestore();
	const classes = useStyles();
	const [userOpen, setUserOpen] = useState(false);
	const [parOpen, setParOpen] = useState(false);
	const [isLoading, setIsLoading] = useState(true);
	const [users, setUsers] = useState([]);
	const [pars, setPars] = useState([]);
	const [loggedUser] = useContext(LoggedUserContext);
	const history = useHistory();

	useEffect(() => {
		const _getUsers = async () => {
			let _users = [];
			let _pars = [];

			const usersRef = db.collection('users');
			const usersSnapshot = await usersRef.where('role', '==', 'User').get();
			if (!usersSnapshot.empty) {
				usersSnapshot.forEach((doc) => _users.push(doc.data()));
			}
			else {
				console.log('EMPTY PARTICIPANTS');
			}

			const parSnapshot = await usersRef.where('role', '==', 'Participant').get();
			if (!parSnapshot.empty) {
				parSnapshot.forEach((doc) => _pars.push(doc.data()));
			}
			else {
				console.log('EMPTY PARTICIPANTS');
			}

			if (loggedUser !== '') {
				const userRef = db.collection('users').doc(loggedUser);
				const userDoc = await userRef.get();
				if (!userDoc.exists) {
					console.log('EMPTY USER');
				}
				else {
					const userData = userDoc.data()
					console.log('USER DATA', userData);

					if (!userData.hasPassword) {
						history.push('/reset-password');
						return;
					}
				}
			}

			setUsers(_users);
			setPars(_pars);
			setIsLoading(false);
		};
		_getUsers();
	}, [db, loggedUser, history]);

	return (
		<>
		{
			isLoading ? 
			<Container maxWidth="lg" style={{textAlign: 'center', margin: '5rem 0'}}>
				<CircularProgress />
			</Container>
			: loggedUser && loggedUser.indexOf('@') === -1 ? 
			<Redirect to="/home" /> :
			<Grid container>
				<Grid item xs={12} className={classes.tableWrapper}>
					<MaterialTable
						title="Manage Admins"
						columns={[
							{
								title: 'Email',
								field: 'username',
								render: (rowData) => <Link to={`/member/${rowData.username}`}>{rowData.username}</Link>,
								cellStyle: { paddingLeft: '24px', paddingRight: '24px' },
								headerStyle: { paddingLeft: '24px', paddingRight: '24px' }
							}
						]}
						data={users}
						options={{
							search: false,
							pageSize: 10,
							pageSizeOptions: [],
							showFirstLastPageButtons: false
						}}
						components={{
							Toolbar: (props) => {
								return (
									<div style={{display: 'flex', alignItems: 'center', justifyContent: 'space-between', padding: '24px 0', backgroundColor: '#373B53', borderTopRightRadius: '4px', borderTopLeftRadius: '4px'}}>
										<Typography gutterBottom variant="h6" component="div" style={{paddingLeft: '24px', paddingRight: '24px', margin: '0', fontWeight: '700', color: '#ffffff'}}>
											{props.title}
										</Typography>
										<Button variant="contained" color="primary" onClick={() => setUserOpen(true)} style={{marginLeft: '24px', marginRight: '24px'}}>
											Add Admin
										</Button>
									</div>
								)
							}
						}}
					/>
					<UserDialog 
						open={userOpen}
						setOpen={setUserOpen}
						setUsers={setUsers}
					/>
				</Grid>
				<Grid item xs={12} className={classes.tableWrapper}>
					<MaterialTable
						title="Manage Participants"
						columns={[
							{
								title: 'Participant ID',
								field: 'username',
								render: (rowData) => <Link to={`/member/${rowData.username}`}>{rowData.username}</Link>,
								cellStyle: { paddingLeft: '24px', paddingRight: '24px' },
								headerStyle: { paddingLeft: '24px', paddingRight: '24px' }
							},
						]}
						data={pars}
						options={{
							search: false,
							pageSize: 10,
							pageSizeOptions: [],
							showFirstLastPageButtons: false
						}}
						components={{
							Toolbar: (props) => {
								return (
									<div style={{display: 'flex', alignItems: 'center', justifyContent: 'space-between', padding: '24px 0', backgroundColor: '#373B53', borderTopRightRadius: '4px', borderTopLeftRadius: '4px'}}>
										<Typography gutterBottom variant="h6" component="div" style={{paddingLeft: '24px', paddingRight: '24px', margin: '0', fontWeight: '700', color: '#ffffff'}}>
											{props.title}
										</Typography>
										<Button variant="contained" color="primary" onClick={() => setParOpen(true)} style={{margin: '0 24px'}}>
											Add Participant
										</Button>
									</div>
								)
							}
						}}
					/>
					<ParDialog 
						open={parOpen}
						setOpen={setParOpen}
						setPars={setPars}
					/>
				</Grid>
			</Grid>
		}
		</>
	);
}
