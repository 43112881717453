import React, { useState, useContext, useEffect } from 'react';
import { Dialog, DialogTitle, Grid, TextField, Button, CircularProgress, FormControl, Typography, Slider, DialogContent, DialogActions } from '@material-ui/core';
import moment from 'moment';
import { DatePicker } from "@material-ui/pickers";
import { makeStyles } from '@material-ui/core/styles';

import firebase from '../utils/firebase';
import { NotificationContext } from '../providers/NotificationProvider';
import { DataContext } from '../providers/DataProvider';
import Number from '../components/Number';


const useStyles = makeStyles({
    dialogTitle: {
        backgroundColor: '#373B53',
        padding: '24px 32px',
        color: '#ffffff',
        '& h2': {
            fontSize: '1.5rem',
        },
    },
    dialogFooter: {
        textAlign: 'right',
        padding: '24px 32px',
        backgroundColor: '#373B53',
    },
    gridContainer: {},
    gridItem: {
        margin: '2rem 0 1rem 0',
    },
	gridItemPadded: {
		padding: '0',
		marginLeft: '0',
	},
    control: {
        width: '100%'
    },
    text: {
        fontWeight: '500',
        margin: '0',
    },
    sliderContainer: {
        display: 'block',
        padding: '0',
    },
    slider: {
        // maxWidth: '256px',
        width: '100%',
    },
    sliderLabels: {
        fontSize: '0.85em',
        fontWeight: '600',
        marginTop: '12px'
    },
    submit: {
        paddingLeft: '2rem',
        paddingRight: '2rem'
    },
    track: {
        color: '#373B53',
        height: '12px',
        borderRadius: '15px'
    },
    rail: {
        color: '#373B53',
        height: '12px',
        borderRadius: '15px'
    },
    thumb: {
        color: '#1FCCCD',
        height: '20px',
        width: '20px',
    },
    bold: {
        color: '#1FCCCD',
    },
    textFieldContainer: {
        maxWidth: '324px'
    },
	input: {
		margin: '0 0 1.5rem 0',
        width: '100%'
	},
    primaryButton: {
        color: 'rgba(0,0,0,0.87)',
    }
});

export default function ParDialog({ open, setOpen, setPars, username }) {
	const classes = useStyles();
	const db = firebase.firestore();

	const [isLoading, setIsLoading] = useState(false);
	const [user, setUser] = useState({
		email: '',
		username: '',
		password: '',
		password_confirmation: '',
		role: 'Participant'
	});

	// Data
	const [registration] = useContext(DataContext);
    const [, setText, setVariant] = useContext(NotificationContext);

	// Fields
	const [doses, setDoses] = useState(1);
    const [dateOpen, setDateOpen] = useState(false);
    const [endDateOpen, setEndDateOpen] = useState(false);
    const [date, setDate] = useState(moment().unix());
    const [endDate, setEndDate] = useState(moment().add(1, 'days').unix());
    const [theType] = useState('coffee');
    const [numCoffees, setNumCoffees] = useState(1);
    const [numTeas, setNumTeas] = useState(0);
    const [durationDays, setDurationDays] = useState(1);

	useEffect(() => {
		console.log('date change', date, 'now', moment(), moment().unix());
	}, [date]);

	useEffect(() => {
		if (username) {
			setUser((oldUser) => ({
				...oldUser,
				username: username
			}));
		}
		else {
			
		}
	}, [setUser, username]);

	useEffect(() => {
		if (username) {
			const getData = async () => {
				const registrationRef = db.collection('registrations').doc(username);
				const registrationDoc = await registrationRef.get();
				if (!registrationDoc) {
					console.log('Could not find registration.');
				}
				else {
					const registrationData = registrationDoc.data();
					console.log('registration', registrationData);
					setDoses(registrationData.doses);
					setNumCoffees(registrationData.numCoffees);
					setNumTeas(registrationData.numTeas);
					setDate(registrationData.startedAt);
					setDurationDays(registrationData.durationDays);
					setEndDate(moment.unix(registrationData.startedAt).add(registrationData.durationDays - 1, 'days').unix());
				}
			};
			getData();
		}
	}, [username, db]);

	const submit = async () => {
        setIsLoading(true);
        let obj = {
            doses: parseInt(doses),
            updatedAt: moment().unix(),
            createdAt: moment().unix(),
            startedAt: date,
            email: user.username,
            theType: theType,
            numCoffees: numCoffees,
            numTeas: numTeas,
            durationDays: durationDays,
            hasAgreed: false
        };

        // Set registration
        await db.collection('registrations').doc(user.username).set(obj);
        // setRegistration(obj);
        
        let dailyArray = [];
        for (let i = 0; i < durationDays; i++) {
            let doseArray = {
                doses: [],
            };
            for (let j = 0; j < doses; j++) {
                doseArray.doses.push({});
            }
            dailyArray.push(doseArray);
        }

        // Set records
        const dailyObj = {
            daily: dailyArray
        };
        await db.collection('records').doc(user.username).set(dailyObj);

        setVariant('success');
        setText('Participant created!');
        // history.push('/home');
    };

	const createUser = (e) => {
		setIsLoading(true);
		fetch('https://us-east4-mighty-artemi.cloudfunctions.net/sign-up', {
            method: "POST",
            headers: {
                "Content-Type": "application/json"
            },
            body: JSON.stringify(user)
        }).then((response) => {
        	console.log(response.text());
            setIsLoading(false);
            submit();
            setPars((oldPars) => ([...oldPars, user]));
            setUser({
            	email: '',
				username: '',
				password: '',
				password_confirmation: '',
				role: 'User'
            });
            setOpen(false);
			setVariant('success');
			setText('Participant created!');
            // history.push('/members');
        }, (error) => console.log(error.message));
	};

	const updateSubmit = async () => {
		const registrationRef = db.collection('registrations').doc(user.username);
		const recordsRef = db.collection('records').doc(user.username);

		const registrationDoc = await registrationRef.get();
		const recordsDoc = await recordsRef.get();
		if (!registrationDoc || !recordsDoc) {
			console.log('Could not find registration or record.');
		}
		else if (registrationDoc && recordsDoc) {
			const registrationData = registrationDoc.data();
			const recordsData = recordsDoc.data();

			let registrationObj = {
				doses: parseInt(doses),
				updatedAt: moment().unix(),
				createdAt: registrationData.createdAt,
				startedAt: date,
				email: user.username,
				theType: theType,
				numCoffees: numCoffees,
				numTeas: numTeas,
				durationDays: durationDays,
				hasAgreed: false
			};
			console.log('registration obj', registrationObj);
			await registrationRef.set(registrationObj);

			let updatedRecordsData = [];
			const diffDurationDays = durationDays - registrationData.durationDays;
			const diffDoses = doses - registrationData.doses;
			console.log('DIFF', diffDurationDays, diffDoses);

			// contains new daily data
			let updatedDailyArray = [];
			if (diffDurationDays > 0) {
				for (let i = 0; i < diffDurationDays; i++) {
					let doseArray = {
						doses: [],
					};
					for (let j = 0; j < doses; j++) {
						doseArray.doses.push({});
					}
					updatedDailyArray.push(doseArray);
				}	
			}
			console.log('updated daily', updatedDailyArray);

			// loop through and update daily data
			for (let i = 0; i < recordsData.daily.length; i++) {
				let day = recordsData.daily[i];

				if (day) {
					// console.log(day);
					let doseArray = {
						doses: day.doses,
					};
					for (let j = 0; j < diffDoses; j++) {
						doseArray.doses.push({});
					}
					updatedRecordsData.push(doseArray);
				}
			}
			console.log('updated records', updatedRecordsData);
			// console.log('updated total', updatedRecordsData.concat(updatedDailyArray));

			let combinedArray = updatedRecordsData.concat(updatedDailyArray);
			let updatedCombinedArray = combinedArray.splice(0, durationDays);

			// Set records
			const dailyObj = {
				daily: updatedCombinedArray
			};
			await db.collection('records').doc(user.username).set(dailyObj);
			console.log('daily obj', dailyObj);
			
			setIsLoading(false);

			window.location.reload(false);
		}
	};

	const updateUser = async () => {
		setIsLoading(true);

		try {
			if (user.password !== '' && user.password_confirmation !== '') {
				const userRef = db.collection('users').doc(user.username);
				const userDoc = await userRef.get();
				if (!userDoc) {
					console.log('Could not find user.');
				}
				else {
					const userData = userDoc.data();
					console.log('update user', userData);

					let obj = {
						username: user.username,
						email: userData.email,
						password: user.password,
						password_confirmation: user.password_confirmation,
						role: user.role
					};
					const response = await fetch('https://us-east4-mighty-artemi.cloudfunctions.net/sign-up', {
						method: "POST",
						headers: {
							"Content-Type": "application/json"
						},
						body: JSON.stringify(obj)
					});
		
					console.log(response.text());
					setUser((oldUser) => ({
						...oldUser,
						password: '',
						password_confirmation: ''
					}));
					setOpen(false);
					setVariant('success');
					setText('Participant updated!');
				}
			}

			// Update Submission Tables
			updateSubmit();
		}
		catch (e) {
			console.log(e);
		}
	};

	return (
		<Dialog open={open} onClose={() => setOpen(false)}>
			<DialogTitle className={classes.dialogTitle}>Add Participant</DialogTitle>
			<DialogContent style={{padding: '16px 24px 24px 24px'}}>
				<Grid container>
					<Grid item xs={12} className={classes.gridItem}>
                    	<Typography variant="h6" className={classes.text} style={{display: 'flex', alignItems: 'center', justifyContent: 'flex-start'}}>
                            <Number value={1} />
                            Add information
                        </Typography>
					</Grid>
					<Grid item xs={12} className={classes.gridItemPadded}>
                        <div className={classes.textFieldContainer}>
                            <TextField 
                                name="text"
                                label="Participant ID"
                                variant="outlined"
                                color="primary"
                                value={user.username}
                                onChange={(e) => {
                                    e.persist();
                                    setUser((oldUser) => ({
                                        ...oldUser,
                                        username: e.target.value
                                    }))
                                }}
                                disabled={username ? true : false}
                                className={classes.input}
                            />
                        </div>
					</Grid>
					{
						username ? null :
						<Grid item xs={12} className={classes.gridItemPadded}>
                            <div className={classes.textFieldContainer}>
                                <TextField 
                                    name="text"
                                    label="Email"
                                    variant="outlined"
                                    color="primary"
                                    value={user.email}
                                    onChange={(e) => {
                                        e.persist();
                                        setUser((oldUser) => ({
                                            ...oldUser,
                                            email: e.target.value
                                        }))
                                    }}
                                    disabled={username ? true : false}
                                    className={classes.input}
                                />
                            </div>
						</Grid>
					}
					<Grid item xs={12} className={classes.gridItemPadded}>
                        <div className={classes.textFieldContainer}>
                            <TextField
                                name="password"
                                label="Password"
                                variant="outlined"
                                color="primary"
                                value={user.password}
                                onChange={(e) => {
                                    e.persist();
                                    setUser((oldUser) => ({
                                        ...oldUser,
                                        password: e.target.value
                                    }))
                                }}
                                className={classes.input}
                                type="password"
                            />
                        </div>
					</Grid>
					<Grid item xs={12} className={classes.gridItemPadded}>
                        <div className={classes.textFieldContainer}>
                            <TextField 
                                name="password_confirmation"
                                variant="outlined"
                                label="Password Confirmation"
                                color="primary"
                                value={user.password_confirmation}
                                onChange={(e) => {
                                    e.persist();
                                    setUser((oldUser) => ({
                                        ...oldUser,
                                        password_confirmation: e.target.value
                                    }))
                                }}
                                error={user.password === user.password_confirmation ? false : true}
                                helperText={user.password === user.password_confirmation ? null : 'Confirmation must match password.'}
                                className={classes.input}
                                type="password"
                            />
                        </div>
					</Grid>
					<Grid item xs={12} className={classes.gridItem}>
						<Typography variant="h6" className={classes.text}>
							<Number value={2} /> Select your dosage
						</Typography>
					</Grid>
					<Grid item xs={12} className={classes.gridItemPadded}>
						<FormControl component="fieldset" className={classes.control}>
                            <div className={classes.sliderContainer}>
                                <Slider 
                                    step={1}
                                    value={doses} 
                                    min={1} 
                                    max={6}
                                    aria-labelledby="discrete-slider-always" 
                                    onChange={(_, value) => {
                                        setDoses(value);
                                        setNumCoffees(value);
                                    }}
                                    className={classes.slider}
                                    classes={{
                                        track: classes.track,
                                        rail: classes.rail,
                                        thumb: classes.thumb
                                    }}
                                />
                                <Typography variant="body1" className={classes.sliderLabels}>
                                    {doses} time{doses > 1 ? 's' : ''} daily
                                </Typography>
                            </div>
						</FormControl>
					</Grid>
					<Grid item xs={12} className={classes.gridItem}>
                        <Typography variant="h6" className={classes.text} style={{marginRight: '1rem', verticalAlign: 'middle'}}>
                            <Number value={3} /> Select trial start date
                        </Typography>
					</Grid>
					<Grid item xs={12} className={classes.gridItemPadded}>
						<FormControl component="fieldset" className={classes.control}>
							<div>
                                <div>
                                    <Button style={{width: '256px', padding: '0.5rem 0', margin: '0 auto 1rem auto'}} variant="contained" color="primary" onClick={() => setDateOpen(true)}>
                                        <Typography variant="subtitle1">
                                            <b>{moment.unix(date).format('MMM DD, yyyy')}</b>
                                        </Typography>
                                    </Button>
                                </div>
								<Typography variant="subtitle2" style={{margin: '0.5rem 0'}}>
									<b>Please note: The start date cannot be modified after {registration ? 'the' : 'this'} initial enrollment.</b>
								</Typography>
							</div>
							<DatePicker
								clearable
								open={dateOpen}
								onClose={() => setDateOpen(false)}
								value={moment.unix(date)}
								placeholder="01/23/2020"
								onChange={newDate => {
									setDate(newDate.unix());
									setEndDate(newDate.add(1, 'days').unix());
									console.log(newDate, newDate.unix());
								}}
								format="MM/DD/yyyy"
								TextFieldComponent={() => null}
							/>    
						</FormControl>
					</Grid>
                    <Grid item xs={12} className={classes.gridItem}>
                        <Typography variant="h6" className={classes.text} style={{marginRight: '1rem', verticalAlign: 'middle'}}>
                            <Number value={4} /> Select trial end date
                        </Typography>
                    </Grid>
                    <Grid item xs={12} className={classes.gridItemPadded}>
						<FormControl component="fieldset" className={classes.control}>
							<div>
                                <div>
                                    <Button style={{width: '256px', padding: '0.5rem 0', margin: '0 auto 1rem auto'}} variant="contained" color="primary" onClick={() => setEndDateOpen(true)}>
                                        <Typography variant="subtitle1">
                                            <b>{moment.unix(endDate).format('MMM DD, yyyy')}</b>
                                        </Typography>
                                    </Button>
                                </div>
								<Typography variant="subtitle2" style={{margin: '0.5rem 0'}}>
									<b>Please note: The end date cannot be modified after {registration ? 'the' : 'this'} initial enrollment.</b>
								</Typography>
							</div>
							<DatePicker
								clearable
								open={endDateOpen}
								onClose={() => setEndDateOpen(false)}
								value={moment.unix(endDate)}
								placeholder="01/23/2020"
								onChange={newDate => {
									const newDateDiff = newDate.diff(moment.unix(date), 'days');
									setEndDate(newDate.unix());
									setDurationDays(newDateDiff + 1);
									console.log(newDate, newDateDiff);
								}}
								format="MM/DD/yyyy"
								TextFieldComponent={() => null}
							/>
						</FormControl>
					</Grid>
					<Grid className={classes.gridItem} item xs={12}>
						<Typography className={classes.text} variant="body1" style={{border: '1px solid #1FCCCD', padding: '1rem', margin: '1rem 0 2rem 0'}}>
							The trial will begin on <b className={classes.bold}>{moment.unix(date).format('MMMM DD, yyyy')}</b> and will end on <b className={classes.bold}>{moment.unix(endDate).format('MMMM DD, yyyy')}</b>. The participant will take <b className={classes.bold}>{doses} dose{doses > 1 ? 's' : ''}</b> per day.
						</Typography>
					</Grid>			
				</Grid>
			</DialogContent>
            <DialogActions className={classes.dialogFooter}>
                <Button onClick={() => setOpen(false)} variant="contained" color="secondary" style={{marginRight: '1rem'}}>Close</Button>
                <Button variant="contained" color="primary" onClick={username ? updateUser : createUser}>{ isLoading ? <CircularProgress color="secondary" size="1.5rem" /> : username ? 'Update' : 'Create' }</Button>
            </DialogActions>
		</Dialog>
	);
}
