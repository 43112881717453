import React from 'react';
import { makeStyles } from '@material-ui/core/styles';


const useStyles = makeStyles({
    root: {
        display: 'inline-flex',
        alignItems: 'center',
        justifyContent: 'center',
        borderRadius: '50%',
        border: '2px solid #1FCCCD',
        color: '#373B53',
        fontWeight: 'bold',
        padding: '0.5rem',
        margin: '0 0 0.5rem 0',
        height: '50px',
        width: '50px',
        textAlign: 'center',
        fontSize: '1rem',
    },
});

export default function Number({ value }) {
    const classes = useStyles();

    return (
        <div style={{display: 'inline-block', marginRight: '1rem'}}>
            <div className={classes.root}>
                {value}
            </div>
        </div>
    );
}
