import React, { useContext } from 'react';
import { Dialog, DialogTitle, DialogActions, Button, Grid, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/core';
import moment from 'moment';

import { DataContext } from '../providers/DataProvider';
import { LoggedUserContext } from '../providers/LoggedUserProvider';
import firebase from '../utils/firebase';


const useStyles = makeStyles({
	item: {
		padding: '0.5rem 0'
	}
});

export default function SummaryDialog({ open, setOpen }) {
	const classes = useStyles();
	const db = firebase.firestore();
    const [registration, setRegistration] = useContext(DataContext);
    const [loggedUser] = useContext(LoggedUserContext);

    const updateRegistration = async (e) => {
    	await db.collection('registrations').doc(loggedUser).set({
    		...registration,
    		hasAgreed: true
    	});
		setRegistration((oldRegistration) => ({
			...oldRegistration,
			hasAgreed: true
		}));
    	setOpen(false);
    };

	return (
		<Dialog open={open}>
			<DialogTitle>Trial Summary</DialogTitle>
			<Grid container style={{padding: '0 24px 16px 24px'}}>
				<Grid item xs={12} className={classes.item}>
					<Typography>
						<b>Trial Start Date</b>
					</Typography>
					<Typography>
						Your trial begins on {moment.unix(registration.startedAt).format('MMMM Do, YYYY')}
					</Typography>
				</Grid>
				<Grid item xs={12} className={classes.item}>
					<Typography>
						<b>Trial Completion Date</b>
					</Typography>
					<Typography>
						Your trial ends on {moment.unix(registration.startedAt).add(registration.durationDays, 'days').format('MMMM Do, YYYY')}
					</Typography>
				</Grid>
				<Grid item xs={12} className={classes.item}>
					<Typography>
						<b>Instructions</b>
					</Typography>
					<Typography>
						Drink {registration.doses} {registration.doses > 1 ? 'coffees' : registration.theType} per day and record any notes you may have about your experience.
					</Typography>
				</Grid>
			</Grid>
			<DialogActions>
				<Button onClick={updateRegistration} variant="contained" color="primary">
					Begin Trial
				</Button>
			</DialogActions>
		</Dialog>
	);
}
