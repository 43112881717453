import React, { useContext } from 'react';
import { List, ListItem, ListItemIcon, Tooltip } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { Home, Assessment, People } from '@material-ui/icons';
import { useHistory } from 'react-router-dom';

import { LoggedUserContext } from '../providers/LoggedUserProvider';


const useStyles = makeStyles((theme) => ({
    list: {
        [theme.breakpoints.down('sm')]: {
            padding: '0',
        }
    },
    listItem: {
        padding: '2rem 0',
        justifyContent: 'center',
        [theme.breakpoints.down('sm')]: {
            display: 'inline-block',
            width: '33%',
            textAlign: 'center',
        },
      },
      listItemIcon: {
        textAlign: 'center',
        justifyContent: 'center'
      },
}));

export default function Sidebar() {
    const history = useHistory();
    const classes = useStyles();
    const [loggedUser] = useContext(LoggedUserContext);
    
    return (
        <>
        {
            loggedUser && loggedUser !== '' ?
            <List className={classes.list} style={{paddingTop: '0'}}>
                <Tooltip title="Activity Dashboard" placement="right">
                    <ListItem onClick={() => history.push('/home')} button className={classes.listItem}>
                        <ListItemIcon className={classes.listItemIcon}>
                            <Home color="secondary" />
                        </ListItemIcon>
                    </ListItem>
                </Tooltip>
                {
                    loggedUser.indexOf('@') <= -1 ?
                    <Tooltip title="History" placement="right">
                        <ListItem onClick={() => history.push('/history')} button className={classes.listItem}>
                            <ListItemIcon className={classes.listItemIcon}>
                                <Assessment color="secondary" />
                            </ListItemIcon>
                        </ListItem>
                    </Tooltip> : null
                }
                {
                    loggedUser.indexOf('@') > -1 ? 
                    <Tooltip title="Members" placement="right">
                        <ListItem onClick={() => history.push('/members')} button className={classes.listItem}>
                            <ListItemIcon className={classes.listItemIcon}>
                                <People color="secondary" />
                            </ListItemIcon>
                        </ListItem>
                    </Tooltip> : null
                }
                {/* <Tooltip title="Settings" placement="right">
                    <ListItem onClick={() => {
                        console.log(moment().startOf('day').diff(moment.unix(registration.startedAt).startOf('day'), 'days'));
                        console.log(registration.durationDays);
                       if (moment().startOf('day').diff(moment.unix(registration.startedAt).startOf('day'), 'days') > registration.durationDays) {
                            setVariant('error');
                            setText('You cannot edit your settings after your trial has ended.');
                            history.push('/home');
                        }
                        else {
                            history.push('/settings');
                        } 
                    }} button className={classes.listItem}>
                        <ListItemIcon className={classes.listItemIcon}>
                            <Settings color="secondary" />
                        </ListItemIcon>
                    </ListItem>
                </Tooltip> */}
            </List> : null
        }
        </>
    );
}
