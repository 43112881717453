import React, { useState, useContext, useEffect } from 'react';
import { Card, CardContent, Typography, TextField, Button, CircularProgress } from '@material-ui/core';

import firebase from '../utils/firebase';
import { LoggedUserContext } from '../providers/LoggedUserProvider';
import { NotificationContext } from '../providers/NotificationProvider';


export default function CommentCard() {
    const [notes, setNotes] = useState('');
    const [isLoading, setIsLoading] = useState(false);
    const [loggedUser] = useContext(LoggedUserContext);
    const [, setText, setVariant] = useContext(NotificationContext);
    const db = firebase.firestore();

    useEffect(() => {
        if (loggedUser && loggedUser !== '') {
            const getNotes = async () => {
                const notesRef = db.collection('notes').doc(loggedUser);
                const _notes = await notesRef.get();
                if (_notes.data() && _notes.data().value) {
                    setNotes(_notes.data().value);
                }
            }
            getNotes();
        }
    }, [loggedUser, setNotes, db]);

    const save = async () => {
        if (loggedUser && loggedUser !== '') {
            setIsLoading(true);
            await db.collection('notes').doc(loggedUser).set({
                value: notes,
            });
            setIsLoading(false);
            setVariant('success');
            setText('Your comment was saved!');
        }
    };

    return (
        <Card>
            <CardContent>
                <Typography variant="h6" style={{padding: '1rem 0'}}>
                    Thank you for completing your trial. In summary, please give your overall impression and any final feedback. 
                </Typography>
                <TextField
                    fullWidth
                    multiline
                    placeholder="Type here"
                    rows={4}
                    value={notes}
                    onChange={(event) => setNotes(event.target.value)}
                    style={{
                        margin: '1rem 0',
                    }}
                />
                <div
                    style={{
                        marginTop: '1rem',
                    }}
                >
                    <Button variant="contained" color="primary" disabled={isLoading} onClick={save}>
                        {
                            isLoading ? <CircularProgress /> : 'Save'
                        }
                    </Button>
                </div>
            </CardContent>
        </Card>
    );
}
