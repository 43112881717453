import React, { useState, useContext, useEffect } from 'react';
import { Grid, Typography, Button, Card, CardContent, CircularProgress } from '@material-ui/core';
import MaterialTable from 'material-table';
import moment from 'moment';
import { useParams, Link } from 'react-router-dom';
import { makeStyles } from '@material-ui/core';
import axios from 'axios';

import { LoggedUserContext } from '../providers/LoggedUserProvider';
import { NotificationContext } from '../providers/NotificationProvider';
import CustomDialog from '../components/CustomDialog';
import firebase from '../utils/firebase';
import ParDialog from '../components/ParDialog';
import DeleteDialog from '../components/DeleteDialog';
import UserDialog from '../components/UserDialog';

function EditComponent({ rowData, onChange, columnDef, registration, daily, setDaily }) {
    const db = firebase.firestore();
    const { username } = useParams();
    const [, setText] = useContext(NotificationContext);
    const [open, setOpen] = useState(false);
    const [theType, setTheType] = useState('coffee');
    const [date, setDate] = useState(new Date());
    const [notes, setNotes] = useState('');

    useEffect(() => {
        if (registration && rowData.tableData) {
            const diff = moment.unix(registration.startedAt).add(rowData.tableData.id, 'days');
            diff.set({
                hours: moment().hours(),
                minutes: moment().minutes(),
                seconds: 0,
            });
            console.log(diff);
            setDate(diff.toDate());
        }
    }, [registration, setDate, rowData]);

    useEffect(() => console.log('TYPEEE', theType), [theType]);

    useEffect(() => {
        if (rowData.tableData && registration && daily) {
            const dose = daily.daily[rowData.tableData.id].doses[parseInt(columnDef.field)];
            if (Object.keys(dose).length > 0) {
                // setTheType(daily.daily[rowData.tableData.id].doses[parseInt(columnDef.field)].theType || 'coffee');
                setDate(new Date(moment.unix(dose.date)));
                setNotes(dose.notes);
            }
        }
    }, [rowData, registration, daily, columnDef]);

    const record = async () => {
    	console.log('THE TYPE', theType);
        if (theType === 'coffee' || theType === 'tea' || theType === 'none') {
            if (registration && daily) {
                let _daily = daily;
                console.log('EXSITING TYPE', _daily.daily[rowData.tableData.id].doses[parseInt(columnDef.field)].theType);
                _daily.daily[rowData.tableData.id].doses[parseInt(columnDef.field)] = {
                    theType: _daily.daily[rowData.tableData.id].doses[parseInt(columnDef.field)].theType ? _daily.daily[rowData.tableData.id].doses[parseInt(columnDef.field)].theType : theType,
                    date: moment(date).unix(),
                    notes: notes,
                };

                await db.collection('records').doc(username).set(_daily);
                setDaily(_daily);
                setOpen(false);
                let theTypeDisplay = '';
                if (theType === 'coffee') theTypeDisplay = 'ArtemiCafe\u2122';
                if (theType === 'tea') theTypeDisplay = 'ArtemiTea\u2122';
                onChange(`${theTypeDisplay} on ${moment(date).format('MM/DD/YYYY')} at ${moment(date).format('hh:mma')}`);
                console.log(_daily);
                console.log(rowData);
                console.log('Recorded');
            }
        }
        else {
            setText('Please choose either Coffee or Tea.');
            setOpen(true);
        }
    };

    return (
        <>
            <Button variant="contained" color="primary" onClick={() => {
                console.log(columnDef);
                setOpen(true);
            }}>
                Edit
            </Button>
            <CustomDialog title="Record Daily Dose" open={open} setOpen={setOpen} record={record} date={date} setDate={setDate} theType={theType} setTheType={setTheType} notes={notes} setNotes={setNotes} />
        </>
    );
}

const useStyles = makeStyles({
    header: {
        fontWeight: '600',
        color: '#ffffff',
    },
    card: {
        backgroundColor: '#373B53',
    }
});

export default function Detail() {
    const classes = useStyles();
	const db = firebase.firestore();
    const { username } = useParams();
    const [loggedUser] = useContext(LoggedUserContext);
    const [, setText, setVariant] = useContext(NotificationContext);
    const [registration, setRegistration] = useState({});
    const [daily, setDaily] = useState({});
    const [data, setData] = useState([]);
    const [parOpen, setParOpen] = useState(false);
    const [deleteOpen, setDeleteOpen] = useState(false);
    const [userOpen, setUserOpen] = useState(false);
    const [reportLoading, setReportLoading] = useState(false);
    const [columns, setColumns] = useState(() => {
        let c = [
            {
                title: 'Day',
                field: 'day',
                editable: 'never',
                cellStyle: { paddingLeft: '24px', paddingRight: '24px' },
				headerStyle: { paddingLeft: '24px', paddingRight: '24px' }
            }
        ];
        for (let i = 0; i < registration.doses; i++) {
            c.push({
                title: `Dose ${i + 1}`,
                field: `${i}`,
                editComponent: (props) => (<EditComponent {...props} />),
                cellStyle: { paddingLeft: '24px', paddingRight: '24px' },
				headerStyle: { paddingLeft: '24px', paddingRight: '24px' }
            });
        }
        return c;
    });

    useEffect(() => {
		const getData = async () => {
	        if (loggedUser && loggedUser.indexOf('@') > -1) {
	            console.log('Running get data');
	            const userRef = db.collection('registrations').doc(username);
	            const userDailyRef = db.collection('records').doc(username);

	            const userDaily = await userDailyRef.get();
	            const userDailyData = userDaily.data();
	            console.log('DAILY DATA', userDailyData);
	            console.log(userDailyData);
	            if (userDailyData) {
	                setDaily(userDailyData);
	            }

	            const user = await userRef.get();
	            const userData = user.data();
	            if (userData) {
	                console.log('USER DATA', userData);
	                setRegistration(userData);

	                setColumns(() => {
				        let c = [
				            {
				                title: 'Day',
				                field: 'day',
				                editable: 'never',
				            }
				        ];
				        for (let i = 0; i < userData.doses; i++) {
				            c.push({
				                title: `Dose ${i + 1}`,
				                field: `${i}`,
				                editComponent: (props) => (<EditComponent registration={userData} daily={userDailyData} setDaily={setDaily} {...props} />),
				            });
				        }
				        return c;
				    });
	            }
	        }
	    };
	    getData();
    }, [db, loggedUser, username, setDaily, setRegistration, setColumns]);

    useEffect(() => {
        if (registration && daily && daily.daily) {
            const resolveData = daily.daily.map((d, j) => {
                let _data = {};
                for (let i = 0; i < d.doses.length; i++) {
                    let theTypeDisplay = '';
                    if (d.doses[i].theType === 'coffee') theTypeDisplay = 'ArtemiCafe\u2122';
                    if (d.doses[i].theType === 'tea') theTypeDisplay = 'ArtemiTea\u2122';

                    _data['day'] = moment.unix(registration.startedAt).add(j, 'days').format('MMMM DD, yyyy');
                    _data[`${i}`] = Object.keys(d.doses[i]).length > 0 ? d.doses[i].theType === 'none' ? 'No dose taken.' : `${theTypeDisplay} on ${moment.unix(d.doses[i].date).format('MM/DD/YYYY')} at ${moment.unix(d.doses[i].date).format('hh:mma')}` : '';
                }
                return _data;
            });
            setData(resolveData);
        }
    }, [daily, setData, registration]);

    return (
        <Grid container>
        	<Grid item xs={12}>
            	<Link to="/members">Back to members</Link>
            </Grid>
            <Grid item xs={12} style={{marginTop: '1rem'}}>
                <Card className={classes.card}>
                    <CardContent style={{padding: '1.5rem 24px', display: 'flex', alignItems: 'center', justifyContent: 'space-between'}}>
                        <Typography className={classes.header} variant="h4">{username}</Typography>

                        <div>
                            <Button variant="contained" color="primary" onClick={() => username.indexOf('@') === -1 ? setParOpen(true) : setUserOpen(true)}>
                                Edit
                            </Button>
                            <Button style={{marginLeft: '0.5rem', color: '#ffffff'}} onClick={() => setDeleteOpen(true)}>
                                Delete
                            </Button>
                        </div>
                    </CardContent>
                </Card>
                <Card className={classes.card} style={{marginTop: '1rem'}}>
                    <CardContent style={{padding: '1.5rem 24px', display: 'flex', alignItems: 'center', justifyContent: 'flex-start'}}>
                        <div>
                            <Button variant="contained" color="primary" onClick={async () => {
                                setReportLoading(true);

                                axios.get(`https://hooks.zapier.com/hooks/catch/6986406/bjrsayx?id=${username}&cc=&to=${loggedUser}`)
                                .then((response) => {
                                    console.log('Report Response:', response.data, username);

                                    setVariant('success');
                                    setText('The patient report has been emailed!');
                                    setReportLoading(false);
                                });
                            }}>
                                {
                                    reportLoading ? 
                                        <CircularProgress color='secondary' size='1rem' />
                                    :
                                        'Generate Report'
                                }
                            </Button>
                        </div>
                    </CardContent>
                </Card>
                {
                    username.indexOf('@') === -1 ? 
                    <ParDialog open={parOpen} setOpen={setParOpen} username={username} /> :
                    <UserDialog open={userOpen} setOpen={setUserOpen} username={username} />
                }
                <DeleteDialog open={deleteOpen} setOpen={setDeleteOpen} username={username} />
            </Grid>
            {
                username && username.indexOf('@') > -1 ? null : 
                <Grid item xs={12}>
                    <MaterialTable
                        title={registration ? `Today is ${moment().format('MMMM DD, yyyy')}` : ''}
                        columns={columns}
                        data={data}
                        editable={{
                            onRowUpdate: (newData, oldData) => 
                                new Promise((resolve) => {
                                    console.log(oldData);
                                    const index = oldData.tableData.id;
                                    let _data = [...data];
                                    _data[index] = newData;
                                    setData([..._data]);
                                    resolve();
                                }),
                        }}
                        options={{
							search: false,
							pageSize: 10,
							pageSizeOptions: [],
							showFirstLastPageButtons: false
						}}
                        style={{
                            margin: '1rem 0 0 0',
                            borderRadius: '4px',
                        }}
                        components={{
							Toolbar: (props) => {
								return (
									<div style={{display: 'flex', alignItems: 'center', justifyContent: 'space-between', padding: '24px 0', backgroundColor: '#373B53', borderTopRightRadius: '4px', borderTopLeftRadius: '4px'}}>
										<Typography gutterBottom variant="h6" component="div" style={{paddingLeft: '24px', paddingRight: '24px', margin: '0', fontWeight: '700', color: '#ffffff'}}>
											{props.title}
										</Typography>
									</div>
								)
							}
						}}
                    />
                </Grid>
            }
        </Grid>
    );
}
