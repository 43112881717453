import React from 'react';


export default function Coffee({ progress }) {
    return (
        <svg viewBox="0 0 118 126" style={{height: '100%', width: '100%'}}>
            <g>
                <path d="M78 104L67 111L60 114H52H45L38 112L26 105L21.5 100.5L18 96L14.5 90.5L12 85L9 74V63H94L93.5 74L92.5 79L91 84L89 89L86 94L82.5 99L78 104Z" fill="url(#progress)"/>
                <path d="M27.4415 45.57C28.1172 46.27 29.0603 46.634 29.9893 46.634C30.862 46.634 31.7488 46.312 32.4385 45.64C33.8602 44.296 33.8884 42.084 32.5511 40.67C31.8332 39.886 25.499 32.886 31.3124 25.718C40.2507 14.714 34.6344 4.67603 30.4397 0.910034C28.9758 -0.391966 26.7236 -0.265966 25.4287 1.17603C24.1337 2.64603 24.2322 4.85803 25.6961 6.16003C25.9776 6.44003 32.7763 12.684 25.7806 21.28C17.8557 31.024 23.4158 41.426 27.4415 45.57Z" fill="black"/>
                <path d="M47.7814 45.57C48.4711 46.27 49.4142 46.634 50.3291 46.634C51.2159 46.634 52.1168 46.312 52.8065 45.626C54.2141 44.268 54.2423 42.028 52.8769 40.642C52.5813 40.334 45.6418 33.082 51.6523 25.704C60.5765 14.7 54.9601 4.676 50.7655 0.895996C49.2875 -0.406004 47.0494 -0.280004 45.7263 1.162C44.4313 2.618 44.5439 4.844 46.0078 6.146C46.2893 6.426 53.088 12.67 46.1063 21.266C38.2097 31.024 43.7556 41.426 47.7814 45.57Z" fill="black"/>
                <path d="M68.0229 45.57C68.7408 46.27 69.6416 46.634 70.5706 46.634C71.4433 46.634 72.3442 46.312 73.0339 45.64C74.4415 44.296 74.4697 42.084 73.1325 40.67C72.4005 39.886 66.0804 32.886 71.9079 25.718C80.8321 14.714 75.2157 4.67601 71.0211 0.91001C69.5712 -0.37799 67.3613 -0.26599 66.0382 1.16201C64.7291 2.60401 64.8135 4.83001 66.2634 6.16001C66.2774 6.17401 68.1636 7.93801 69.0645 10.696C70.1906 14.126 69.2897 17.696 66.376 21.294C58.4512 31.024 63.9971 41.426 68.0229 45.57Z" fill="black"/>
                <path d="M103.635 63.5599H102.213V58.9959C102.213 58.9119 102.185 58.8559 102.185 58.7579C102.185 58.6879 102.213 58.6179 102.213 58.5199C102.213 55.8599 100.341 53.6899 98.0469 53.6899H4.86357C3.08999 53.6899 1.5557 54.9919 0.964509 56.8119C0.682988 57.4699 0.5 58.2119 0.5 58.9959V65.5899C0.5 67.0319 0.57038 68.4739 0.654836 69.8879C2.00614 90.8599 12.9714 108.738 28.4409 117.586C28.638 122.29 31.9459 126.014 36.0279 126.014H36.1687H36.2391H66.5025C66.8685 126.014 67.1782 125.958 67.516 125.874C70.8098 125.16 73.3716 121.94 73.6813 117.964C82.5492 113.078 89.9814 105.238 95.0347 95.5359C95.4429 95.6899 95.8511 95.7879 96.3015 95.7879H103.663C111.321 95.7879 117.542 88.5639 117.542 79.6879C117.5 70.7839 111.278 63.5599 103.635 63.5599ZM93.613 66.1499C93.3596 92.3719 74.9341 113.596 52.2998 113.596H50.3995C27.7794 113.596 9.3679 92.3719 9.11453 66.1499V63.3639H93.6271V66.1499H93.613ZM103.635 86.9259H98.6944C100.257 82.3479 101.341 77.4759 101.847 72.4219H103.621C107.084 72.4219 109.871 75.6699 109.871 79.6739C109.871 83.6639 107.084 86.9259 103.635 86.9259Z" fill="black"/>
            </g>
            <defs>
                <linearGradient id="progress" x1="0" y1="1" x2="0" y2="0">
                    <stop id="stop1" offset={progress} stopColor="#1BC1AD"/>
                    <stop id="stop2" offset="0" stopColor="#ffffff"/>
                </linearGradient>
            </defs>
        </svg>
    );
}
