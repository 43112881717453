const isEmail = (s) => s.indexOf('@') > -1 && s.indexOf('.') > -1;

const notEmpty = (s) => s !== '';

const confirmationMatches = (original, confirmation) => (original === confirmation) && original !== '' && confirmation !== '';

export  {
    isEmail,
    notEmpty,
    confirmationMatches
};
