import React from 'react';


export default function Logo(props) {
    return (
        <svg {...props} width="200px" version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg" x="0px" y="0px"
	 viewBox="0 0 700 100" style={{ enableBackground: 'new 0 0 700 100', verticalAlign: 'bottom' }}>
            <polygon className="st0" style={{fill: '#1FCCCD'}} points="451.73,20.57 441.99,76 473.5,76 474.61,69.66 449.75,69.66 458.38,20.57 "/>
            <polygon className="st0" style={{fill: '#1FCCCD'}} points="500.58,20.57 490.84,76 497.5,76 507.24,20.57 "/>
            <polygon className="st0" style={{fill: '#1FCCCD'}} points="530.43,20.57 520.69,76 527.35,76 531.78,50.74 554.83,50.74 555.93,44.4 532.89,44.4 535.98,26.9 
                560.61,26.9 561.71,20.57 "/>
            <polygon className="st0" style={{fill: '#1FCCCD'}} points="580.95,20.57 571.21,76 603.68,76 604.79,69.66 578.98,69.66 582.3,50.74 605.74,50.74 606.85,44.4 
                583.41,44.4 586.5,26.9 611.84,26.9 612.95,20.57 "/>
            <g>
                <path d="M96.53,21L76,76.26h13.34l14.72-42.59l5.14,14.96c6.6,0.44,11.28,4.01,11.28,4.01l-9.85,0.14l0.22,0.64l6.4-0.4l-5.16,4
                    l6.62,19.24h13.5L111.68,21H96.53z"/>
                <path d="M186.18,39.39c0-10.5-8.6-18.39-20.6-18.39h-21.63v55.26h12.32V58.42h7.38l10.7,17.84h14.76l-12.83-20.6
                    C182.11,52.1,186.18,46.02,186.18,39.39z M165.41,48.08h-9.16V32.29h9.16c4.97,0,8.29,3.63,8.29,7.89
                    C173.7,44.44,170.39,48.08,165.41,48.08z"/>
                <polygon points="196.8,32.68 211.64,32.68 211.64,76.26 224.11,76.26 224.11,32.68 238.96,32.68 238.96,21 196.8,21 	"/>
                <polygon points="267.46,53.92 287.91,53.92 287.91,42.39 267.46,42.39 267.46,32.21 291.62,32.21 291.62,21 255.15,21 
                    255.15,76.26 292.72,76.26 292.72,64.89 267.46,64.89 	"/>
                <polygon points="342.46,59.05 330.03,21 310.22,21 310.22,76.26 322.33,76.26 322.33,33.08 324.35,39.94 337.21,76.26 
                    347.71,76.26 360.58,39.94 362.71,32.8 362.71,76.26 374.79,76.26 374.79,21 354.9,21 	"/>
                <rect x="394.54" y="21" width="12.55" height="55.26"/>
                <g>
                    <path d="M120.49,52.64c-3.88-1.66-7.9-2.26-12.06-1.51c-3.7,0.67-7.01,2.29-9.94,4.64c-3.87,3.1-6.75,7-9.08,11.35
                        c-1.25,2.32-2.24,4.74-3.09,7.2c1.19,0.46,2.22,1.12,3.06,1.94c2.51-4.89,5.59-9.42,9.52-13.39c4.48-4.54,9.65-7.9,15.96-9.32
                        c0.23-0.05,0.47-0.09,0.71-0.12c0.38-0.08,0.77-0.17,1.16-0.24c0,0-15.73,8.11-20.71,20.77C96,73.98,95.99,74,95.99,74.02
                        c3.96-0.24,7.49-1.63,10.49-4.47c2.16-2.05,3.79-4.51,5.49-6.93c1.52-2.15,3.01-4.34,4.69-6.36c1.11-1.33,2.54-2.4,3.85-3.61
                        C120.54,52.72,120.53,52.65,120.49,52.64z"/>
                </g>
            </g>
            <text transform="matrix(1 0 0 1 622.6504 26.7793)" className="st0 st1 st2">TM</text>
        </svg>
    );
}
