import React, { useContext } from 'react';
import { Grid, Typography, Card, CardContent, Divider, Select, MenuItem } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import withWidth, { isWidthDown } from '@material-ui/core/withWidth';
import { Circle } from 'rc-progress';
import { BarChart, Bar, XAxis, YAxis, ResponsiveContainer } from 'recharts';
import moment from 'moment';

import Calendar from '../svgs/Calendar';
import Coffee from '../svgs/Coffee';
import { DataContext } from '../providers/DataProvider';


const useStyles = makeStyles((theme) => ({
    container: {
        // width: '300px', 
        margin: '2rem auto 0 auto',
        justifyContent: 'center',
    },
    responsiveWrapper: {
        height: '250px',
        [theme.breakpoints.down('sm')]: {
            height: '400px',
        },
    },
    title: {
        textAlign: 'center',
        fontSize: '1.5em',
        padding: '0.5rem',
    },
    label: {
        position: 'absolute',
        bottom: 0,
        color: 'rgba(34, 34, 34, 0.7)',
    },
    h2: {
        fontWeight: 'bold',
        fontSize: '2em',
        fontFamily: '"Inter", sans-serf',
    },
    item: {
        fontSize: '1.25em',
    },
    card: {
        height: '100%',
    }
}));

function Progress({ title, variant, value, target, data, day, setDay, width }) {
    const classes = useStyles();
    const [registration] = useContext(DataContext);

    return (
        <Card className={classes.card}>
            <CardContent className={classes.card}>
                <Grid container>
                    <Grid item xs={12}>
                        <Typography variant="h3" className={classes.title}>
                            {title}
                        </Typography>
                    </Grid>
                    <Grid item xs={12}>
                        <Divider />
                    </Grid>
                    {
                        variant === 'weekly' && (
                            <Grid item xs={12} style={{margin: '1rem 0 2rem 0'}}>
                                <Select
                                    className={classes.item}
                                    value={day < 0 ? 0 : day}
                                    onChange={(event) => setDay(event.target.value)}
                                >
                                    {
                                        Array(registration.durationDays).fill(0).map((_, i) => (
                                            <MenuItem className={classes.item} key={`progress-day-${i}`} value={i}>
                                                {moment.unix(registration.startedAt).add(i, 'days').format('MMMM DD')}
                                            </MenuItem>
                                        ))
                                    }
                                </Select>
                            </Grid>
                        )
                    }
                    <Grid item xs={12} style={{textAlign: 'center'}}>
                        {
                            variant === 'monthly' && (
                                <Grid container className={classes.container}>
                                    <Grid item xs={12} style={{position: 'relative', height: '180px', padding: '1rem'}}>
                                        <Circle strokeColor="#1FCCCD" strokeWidth={5} trailWidth={4} percent={(value / target) * 100} gapDegree={180} gapPosition="bottom" style={{marginBottom: '-60%'}} />
                                        {/* <span className={classes.label} style={{left: '5px', bottom: '-1rem', paddingLeft: '1rem'}}>{value && target ? ((value / target) * 100).toFixed(0) : 0}%</span> */}
                                        {/* <span className={classes.label} style={{right: '-5px', bottom: '-1rem', paddingRight: '1rem'}}>100%</span> */}
                                    </Grid>
                                    <Grid item xs={12} style={{marginTop: '-3rem'}}>
                                        <div style={{
                                            backgroundColor: 'rgba(101,223,224,0.22)',
                                            height: '85px',
                                            width: '85px',
                                            margin: '0 auto 1rem auto',
                                            display: 'flex',
                                            alignItems: 'center',
                                            justifyContent: 'center',
                                            borderRadius: '50%',
                                        }}>
                                            <Calendar style={{verticalAlign: 'middle'}} />
                                        </div>
                                        <Typography variant="h2" className={classes.h2}>{value && target ? ((value / target) * 100).toFixed(2) : 0}%</Typography>
                                    </Grid>
                                    <Typography variant="subtitle1" style={{fontWeight: '500', margin: '0.5rem 0'}}>
                                        {value} of {target} doses
                                    </Typography>
                                </Grid>
                            )
                        }
                        {
                            variant === 'weekly' && (
                                <div className={classes.responsiveWrapper}>
                                    <ResponsiveContainer width="100%" height="100%">
                                        <BarChart data={data} margin={{top: 5, right: 30, left: 20, bottom: 5}} style={{marginLeft: 'auto', marginRight: 'auto'}} layout={isWidthDown('sm', width) ? 'vertical' : 'horizontal'}>
                                            <Bar 
                                                margin={{bottom: 16}} 
                                                barSize={35} 
                                                dataKey="doses" 
                                                fill="#1FCCCD" 
                                                fontWeight="bold" 
                                                radius={[10, 10, 10, 10]}
                                                label={({ x, y, value }) => (
                                                    <text 
                                                        x={x} 
                                                        y={y} 
                                                        dy={isWidthDown('sm', width) ? 24 : 30}
                                                        dx={17.5} 
                                                        fontSize={isWidthDown('sm', width) ? '16px' : '20px'} 
                                                        fontFamily="sans-serif"
                                                        fontWeight="bold" 
                                                        fill="white" 
                                                        textAnchor="middle" 
                                                    >
                                                        {value}
                                                    </text>
                                                )}
                                            />
                                            {
                                                isWidthDown('sm', width) && (
                                                    <YAxis axisLine={false} type="category" dataKey="day" tickSize={0} dx={-5} />
                                                )
                                            }
                                            {
                                                isWidthDown('sm', width) ? 
                                                <XAxis axisLine={false} tickSize={0} tick={false} type="number" /> :
                                                <XAxis axisLine={false} dataKey="day" tickSize={0} dy={10} />
                                            }
                                        </BarChart>
                                    </ResponsiveContainer>
                                </div>
                            )
                        }
                        {
                            variant === 'daily' && (
                                <Grid container className={classes.container}>
                                    <Grid item xs={12} style={{textAlign: 'center'}}>
                                        <div style={{height: '180px', width: '180px', paddingBottom: '2rem', margin: '0 auto', transform: 'translateX(4px)'}}>
                                            <Coffee progress={value / target} />
                                        </div>
                                    </Grid>
                                    <Grid item xs={12}>
                                        <Typography variant="h2" className={classes.h2}>
                                            {value && target ? ((value / target) * 100).toFixed(2) : 0}%
                                        </Typography>
                                    </Grid>
                                    <Typography variant="subtitle1" style={{fontWeight: '500', margin: '0.5rem 0'}}>
                                        {value} of {target} doses
                                    </Typography>
                                </Grid>
                            )
                        }
                    </Grid>
                </Grid>
            </CardContent>
        </Card>
    );
}

export default withWidth()(Progress);
