import firebase from 'firebase/app';
import 'firebase/auth';
import 'firebase/firestore';

const firebaseConfig = {
    apiKey: "AIzaSyB7l_wjRE6UdhTbvVbQpyTF0ACJ0-inW-0",
    authDomain: "mighty-artemi.firebaseapp.com",
    databaseURL: "https://mighty-artemi.firebaseio.com",
    projectId: "mighty-artemi",
    storageBucket: "mighty-artemi.appspot.com",
    messagingSenderId: "581304859345",
    appId: "1:581304859345:web:7d8346076cb15f9ccbe3f0"
  };
firebase.initializeApp(firebaseConfig);

export default firebase;
