import React from 'react';


export default function LogoA(props) {
    return (
        <svg {...props} version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg" x="0px" y="0px"
	 viewBox="0 0 68 100">
            <g>
                <path d="M27.53,21L7,76.26h13.34l14.72-42.59l5.14,14.96c6.6,0.44,11.28,4.01,11.28,4.01l-9.85,0.14l0.22,0.64l6.4-0.4l-5.16,4
                    l6.62,19.24h13.5L42.68,21H27.53z"/>
                <g>
                    <path d="M51.49,52.64c-3.88-1.66-7.9-2.26-12.06-1.51c-3.7,0.67-7.01,2.29-9.94,4.64c-3.87,3.1-6.75,7-9.08,11.35
                        c-1.25,2.32-2.24,4.74-3.09,7.2c1.19,0.46,2.22,1.12,3.06,1.94c2.51-4.89,5.59-9.42,9.52-13.39c4.48-4.54,9.65-7.9,15.96-9.32
                        c0.23-0.05,0.47-0.09,0.71-0.12c0.38-0.08,0.77-0.17,1.16-0.24c0,0-15.73,8.11-20.71,20.77C27,73.98,26.99,74,26.99,74.02
                        c3.96-0.24,7.49-1.63,10.49-4.47c2.16-2.05,3.79-4.51,5.49-6.93c1.52-2.15,3.01-4.34,4.69-6.36c1.11-1.33,2.54-2.4,3.85-3.61
                        C51.54,52.72,51.53,52.65,51.49,52.64z"/>
                </g>
            </g>
        </svg>
    );
}
