export default (arr) => {
    let counter = 0;
    for (let i = 0; i < arr.length; i++) {
        if (Object.keys(arr[i]).length === 0) {
            counter = i;
            break;
        }
    }
    console.log('DOSE COUNTER', counter);
    return counter;
};
