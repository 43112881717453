import React from 'react';
import { Switch, Route, Redirect } from 'react-router-dom';
import { useCookies } from 'react-cookie';

import Authenticate from './pages/Authenticate';
import Tracker from './pages/Tracker';
import Form from './pages/Form';
import History from './pages/History';
import Members from './pages/Members';
import Detail from './pages/Detail';
import ResetPassword from './pages/ResetPassword';


function ProtectedRoute(routeProps) {
    const [cookies] = useCookies(['user']);

    return cookies.users ? (
        <Route {...routeProps} />
    ) : (
        <Redirect to="/" />
    );
}

export default function Routes() {
    return (
        <Switch>
            <Route path="/" exact={true} component={Authenticate} />
            <ProtectedRoute path="/home" component={Tracker} validate="needsData" />
            <ProtectedRoute path="/history" component={History} validate="needsData" />
            <ProtectedRoute path="/settings" component={Form} />
            <ProtectedRoute path="/members" component={Members} />
            <ProtectedRoute path="/member/:username" component={Detail} />
            <ProtectedRoute path="/reset-password" component={ResetPassword} />
        </Switch>
    );
};
