import React, { useState, useContext, useEffect } from 'react';
import { Grid, Button, CircularProgress, Typography, Container, Card, CardContent } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { Add } from '@material-ui/icons';
import moment from 'moment';
import { useCookies } from 'react-cookie';
import { useHistory, Link } from 'react-router-dom';
// import MaterialTable from 'material-table';

import SummaryDialog from '../components/SummaryDialog';
import CommentCard from '../components/CommentCard';
import UpTrend from '../svgs/UpTrend';
import DownTrend from '../svgs/DownTrend';
import Sun from '../svgs/Sun';
import Happy from '../svgs/Happy';
import CalendarAdd from '../svgs/CalendarAdd';
import Progress from '../components/Progress';
import CustomCard from '../components/CustomCard';
import CustomDialog from '../components/CustomDialog';
import { LoggedUserContext } from '../providers/LoggedUserProvider';
import { DataContext } from '../providers/DataProvider';
import { NotificationContext } from '../providers/NotificationProvider';
import firebase from '../utils/firebase';
import emptyObjects from '../utils/emptyObjects';
import emptyDoses from '../utils/emptyDoses';


const useStyles = makeStyles((theme) => ({
    container: {
        [theme.breakpoints.down('sm')]: {
            margin: '0',
        },
    },
    counter: {
        display: 'inline-flex',
        alignItems: 'center',
        justifyContent: 'center',
        height: '70px',
        width: '70px',
        margin: '1rem',
        borderRadius: '50%',
        color: '#ffffff',
        backgroundColor: '#1FCCCD',
        verticalAlign: 'middle'
    },
    link: {
        cursor: 'pointer',
        color: '#ffffff',
    },
    item: {
        marginBottom: '1rem',
        [theme.breakpoints.down('sm')]: {
            marginBottom: '0',
        },
    },
    recordButton: {
        margin: '1rem 0',
        fontSize: '1.35em'
    },
    circularContent: {
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)'
    },
    percentage: {
        color: '#C4C4C4',
        fontSize: '1.25rem',
        fontWeight: 'bold'
    },
    buttonWrapper: {
        textAlign: 'right',
    },
    button: {
        [theme.breakpoints.down('sm')]: {
            width: '100%',
        },
    },
    participantUser: {
        '& .MuiTableFooter-root': {
            '& .MuiToolbar-root': {
                paddingTop: '2rem',

                '& .MTablePaginationInner-root-35': {
                    '& > span': {
                        margin: '0 0.75rem',
                    },
                },
            },
        },
        '& .MuiTableCell-footer': {
            borderBottom: 'none',
        },
    },
}));

export default function Tracker() {
    const classes = useStyles();
    const [loggedUser] = useContext(LoggedUserContext);
    const [registration,, daily, setDaily] = useContext(DataContext);
    const [, setText, setVariant] = useContext(NotificationContext);
    const [cookies] = useCookies(['user']);
    const db = firebase.firestore();
    const history = useHistory();

    const [theType, setTheType] = useState('coffee');
    const [day, setDay] = useState(-1);
    const [chartDay, setChartDay] = useState(0);
    const [dose, setDose] = useState(-1);
    const [behindBy, setBehindBy] = useState(0);
    const [selectedDate, setSelectedDate] = useState(new Date());
    const [notes, setNotes] = useState('');
    const [isLoading, setIsLoading] = useState(true);
    const [dialogOpen, setDialogOpen] = useState(false);
    const [summaryOpen, setSummaryOpen] = useState(false);
    const [daysSinceEnd, setDaysSinceEnd] = useState(0);
    // const [hour, setHour] = useState('');
    const [data, setData] = useState([]);
    const [users, setUsers] = useState([]);
    const [completedUsers, setCompletedUsers] = useState([]);

    // Snackbar
    const [, setOpen] = useState(false);

    const greetingSubtitle = () => {
        if (day === -1 & dose === -1) {
            return `Please wait until the first day of your trial on ${moment.unix(registration.startedAt).format('MMMM DD, yyyy')} to record your dose.`;
        }
        else if (day === 0 && dose === 0) {
            return 'Welcome to your first day.';
        }
        else {
            return `You are on Day ${day + 1}.`;
        }
    };

    useEffect(() => {
        if (registration && daily && daily.daily && loggedUser.indexOf('@') <= -1) {
            const daysDiff = moment().startOf('day').diff(moment.unix(registration.startedAt).startOf('day'), 'days');
            console.log('DIFF', daysDiff);
            if (daysDiff >= 0) {
                let foundEmpty = false;
                let dayIndex = daysDiff;
                let doseIndex = 0;
                
                console.log('DAYS', daysDiff);
                console.log('LENGTH', daily.daily.length);
                if (daysDiff < daily.daily.length) {
                    const dosesLength = daily.daily[dayIndex].doses.length;
                    for (let j = 0; j < dosesLength; j++) {
                        if (Object.keys(daily.daily[dayIndex].doses[j]).length === 0) {
                            doseIndex = j;
                            foundEmpty = true;
                            console.log('found', j);
                            break;
                        }
                    }

                    if (!foundEmpty) {
                        doseIndex = daily.daily[dayIndex].doses.length;
                    }
    
                    console.log('DAY', dayIndex);
                    console.log('DOSE', doseIndex);
                    console.log(daily.daily[dayIndex].doses[doseIndex]);
    
                    setDay(dayIndex);
                    setDose(doseIndex);
                    
                    let dayCounter = 0;
                    for (let i = 0; i < dayIndex; i++) {
                        let counter = 0;
                        for (let j = 0; j < daily.daily[i].doses.length; j++) {
                            if (Object.keys(daily.daily[i].doses[j]).length === 0) {
                                console.log('Behind by loop', i);
                                counter += 1;
                            }
                        }

                        if (counter > 0) {
                            console.log('COUNTER', counter);
                            dayCounter += 1;
                        }
                        counter = 0;
                        console.log('DAY COUNTER', dayCounter);
                    }
                    setBehindBy(dayCounter);
    
                    let days = daily.daily.map((d, i) => {
                        return {
                            ...d,
                            date: moment.unix(registration.startedAt).add(i, 'days').format('ddd MM/DD'),
                        };
                    });
                    console.log('the daily', days);
                    if (days.length > 7) {
                        days = days.slice(Math.max(daysDiff - 6, 0), daysDiff + 1);
                    }
                    setData(days.map((d) => {
                        return {
                            day: d.date,
                            doses: d.doses.filter((dose) => Object.keys(dose).length > 0 ? true : false).length
                        };
                    }));
                    setChartDay(daysDiff);
                }
                else if (daysDiff >= daily.daily.length) {
                    setDay(-2);
                    setDose(-2);
                    setSelectedDate(moment.unix(registration.startedAt).add(registration.durationDays - 1, 'days').toDate());
                    setDaysSinceEnd(moment.unix(registration.startedAt).startOf('day').add(registration.durationDays, 'days').add(7, 'days').diff(moment().startOf('day'), 'days'));

                    const days = daily.daily.map((d, i) => {
                        return {
                            ...d,
                            date: moment.unix(registration.startedAt).add(i, 'days').format('ddd MM/DD'),
                        };
                    });
                    setData(days.length < 7 ? days : days.slice(days.length - 6, days.length).map((d) => {
                        return {
                            day: d.date,
                            doses: d.doses.filter((dose) => Object.keys(dose).length > 0 ? true : false).length
                        };
                    }));
                    setChartDay(daily.daily.length - 1);
                }
            }   
        }

        if (registration.hasAgreed === false) {
            setSummaryOpen(true);
        }
        
        console.log('registration', registration);
        if (Object.keys(registration).length !== 0 || cookies.users.indexOf('@') > -1) setIsLoading(false);
    }, [daily, registration, cookies.users, loggedUser]);

    const updateChartDay = (_chartDay) => {
        if (daily && daily.daily) {
            console.log(_chartDay);
            const days = daily.daily.map((d, i) => {
                return {
                    ...d,
                    date: moment.unix(registration.startedAt).add(i, 'days').format('ddd MM/DD'),
                };
            }).slice(Math.max(_chartDay - 6, 0), _chartDay + 1);
            console.log('DAYS', days);
            setData(days.map((d, i) => {
                return {
                    day: d.date,
                    doses: d.doses.filter((dose) => Object.keys(dose).length > 0 ? true : false).length,
                };
            }));
            setChartDay(_chartDay);
        }
    };

    const record = async () => {
        if (theType === 'coffee' || theType === 'tea' || theType === 'none') {
            if (registration && registration.doses > dose && daily) {
                let _daily = daily;
                const diff = moment(selectedDate).startOf('day').diff(moment.unix(registration.startedAt).startOf('day'), 'days');
                console.log('RECORD DIFF', diff);
                const _doses = _daily.daily[diff].doses;
                const _doseIndex = emptyDoses(_doses);
                _doses[_doseIndex] = {
                    theType: theType,
                    date: moment(selectedDate).unix(),
                    notes: notes
                };

                await db.collection('records').doc(cookies.users).set(_daily);
                console.log(_daily);
                setDaily(_daily);
                setNotes('');
                setOpen(false);
                setVariant('success');
                setText('Your dose was successfully recorded!');
                console.log('DOSE DAY DIFF', moment().startOf('day').diff(moment(selectedDate).startOf('day'), 'days'));
                if (moment().startOf('day').diff(moment(selectedDate).startOf('day'), 'days') === 0) {
                    setDose(registration.doses > dose ? dose + 1 : dose);
                }

                let dayCounter = 0;
                for (let i = 0; i < day; i++) {
                    let counter = 0;
                    for (let j = 0; j < daily.daily[i].doses.length; j++) {
                        if (Object.keys(daily.daily[i].doses[j]).length === 0) {
                            counter += 1;
                        }
                    }

                    if (counter > 0) {
                        // console.log('COUNTER', counter);
                        dayCounter += 1;
                    }
                    counter = 0;
                    // console.log('DAY COUNTER', dayCounter);
                }
                setBehindBy(dayCounter);

                const days = daily.daily.map((d, i) => {
                    return {
                        ...d,
                        date: moment.unix(registration.startedAt).add(i, 'days').format('ddd MM/DD'),
                    };
                }).slice(Math.max(chartDay - 6, 0), chartDay + 1);
                setData(days.map((d, i) => {
                    return {
                        day: d.date,
                        doses: d.doses.filter((dose) => Object.keys(dose).length > 0 ? true : false).length,
                    };
                }));
            }
            else {
                setVariant('error');
                setText("You have already taken all of your doses today. Record previous days in Your History.");
            }
        }
        else {
            setOpen(true);
        }
    };

    useEffect(() => {
        if (loggedUser.indexOf('@') > -1) {
            const _fetchUsers = async () => {
                const usersRef = db.collection('users');
                const usersSnapshot = await usersRef.where('role', '==', 'Participant').get();
                if (!usersSnapshot.empty) {
                    usersSnapshot.forEach(async (doc) => {
                        const userData = doc.data();
                        console.log('THE USER DATA', userData);
                        let registrationData = {};
                        let recordData = {};
                        let noteData = {};
                        let tableData = [];

                        const registrationRef = db.collection('registrations').doc(userData.username);
                        const registrationDoc = await registrationRef.get();
                        if (!registrationDoc.exists) {
                            console.log('EMPTY REGISTRATION');
                        }
                        else {
                            registrationData = registrationDoc.data();
                            console.log('Registration doc', registrationData);
                        }

                        const noteRef = db.collection('notes').doc(userData.username);
                        const noteDoc = await noteRef.get();
                        if (!noteDoc.exists) {
                            console.log('NO NOTES');
                        }
                        else {
                            noteData = noteDoc.data();
                            console.log('Note doc', noteData);
                        }

                        const recordRef = db.collection('records').doc(userData.username);
                        const recordDoc = await recordRef.get();
                        if (!recordDoc.exists) {
                            console.log('EMPTY RECORDS');
                        }
                        else {
                            recordData = recordDoc.data();
                            console.log('Record doc', recordData);

                            if (recordData.daily && recordData.daily.length > 0) {
                                tableData = recordData.daily.map((d, j) => {
                                    let _data = {};
                                    for (let i = 0; i < d.doses.length; i++) {
                                        let theTypeDisplay = '';
                                        if (d.doses[i].theType === 'coffee') theTypeDisplay = 'ArtemiCafe\u2122';
                                        if (d.doses[i].theType === 'tea') theTypeDisplay = 'ArtemiTea\u2122';
                                        _data['day'] = moment.unix(registrationData.startedAt).add(j, 'days').format('MMMM DD, yyyy');
                                        _data[`${i}`] = Object.keys(d.doses[i]).length > 0 ? d.doses[i].theType === 'none' ? 'No doses taken.' : `${theTypeDisplay} on ${moment.unix(d.doses[i].date).format('MM/DD/YYYY')} at ${moment.unix(d.doses[i].date).format('hh:mma')}` : '';
                                    }
                                    return _data;
                                });
                                console.log('new table data', tableData);
                            }
                        }

                        if (moment.unix(registrationData.startedAt).add(registrationData.durationDays + 1, 'days').isBefore(moment(), 'day')) {
                            setCompletedUsers((prevUsers) => [...prevUsers, {
                                user: doc.data(),
                                registration: registrationData,
                                records: recordData,
                                data: tableData,
                                note: noteData
                            }]);
                        }
                        else {
                            setUsers((prevUsers) => [...prevUsers, {
                                user: doc.data(),
                                registration: registrationData,
                                records: recordData,
                                data: tableData,
                                note: noteData
                            }]);
                        }                        
                    });
                }
                else {
                    console.log('EMPTY PARTICIPANTS');
                }
            };
            _fetchUsers();
        }
    }, [db, loggedUser]);

    useEffect(() => {
        if (loggedUser !== '') {
            const _getUser = async () => {
                const userRef = db.collection('users').doc(loggedUser);
                const userDoc = await userRef.get();
                if (!userDoc.exists) {
                    console.log('EMPTY USER');
                }
                else {
                    const userData = userDoc.data()
                    console.log('USER DATA', userData);

                    if (!userData.hasPassword) {
                        history.push('/reset-password');
                        return;
                    }
                }
            };
            _getUser();
        }
    }, [db, loggedUser, history]);

    return (
        <>
        {
            isLoading ? 
            (
                <Container maxWidth="md" style={{textAlign: 'center', margin: '5rem 0'}}>
                    <CircularProgress />
                </Container>
            ) :
            cookies.users.indexOf('@') <= -1 ? 
            <>
                {
                    daysSinceEnd < 0 && day === -2 && dose === -2 && (
                        <Grid container style={{width: '100%', marginTop: '2rem'}}>
                            <Grid item xs={12} className={classes.item}>
                                <CommentCard />
                            </Grid>
                        </Grid>
                    )
                }
                {
                    daysSinceEnd >= 0 && day === -2 && dose === -2 && (
                        <Grid container className={classes.container} spacing={3} style={{width: '100%', marginTop: '2rem', justifyContent: 'center'}}>
                            <Grid item xs={12} sm={6} className={classes.item}>
                                <div style={{display: 'flex', alignItems: 'center', height: '100%'}}>
                                    <Typography variant="h1" style={{fontWeight: '600'}}>
                                        Activity Dashboard
                                    </Typography>
                                </div>
                            </Grid>
                            <Grid item xs={12} sm={6} className={`${classes.item} ${classes.buttonWrapper}`}>
                                <Button className={classes.button} variant="contained" color="primary" onClick={() => setDialogOpen(true)}>
                                    <span style={{padding: '0.5rem 1rem 0.5rem 0.5rem'}}><Add style={{verticalAlign: 'middle'}} /></span>
                                    Record Daily Dose
                                </Button>
                            </Grid>
                            <Grid item xs={12} className={classes.item}>
                                <CommentCard />
                            </Grid>
                            <Grid item xs={12} className={classes.item}>
                                <Grid container spacing={3}>
                                    <Grid item xs={12} sm={6}>
                                        <CustomCard icon={<Happy />} title="Hello!" subTitle="Thank you for participating!" backgroundColor="rgba(255,187,34,0.38)" />
                                    </Grid>
                                    <Grid item xs={12} sm={6}>    
                                        <CustomCard icon={<CalendarAdd />} title={`You have ${daysSinceEnd} days left to record any missed doses.`} backgroundColor="rgba(101,223,224,0.22)" />
                                    </Grid>
                                </Grid>
                            </Grid>
                            <Grid item xs={12} sm={6} className={classes.item}>
                                <Progress title={`${registration.durationDays}-Day Progress`} variant="monthly" value={emptyObjects(daily.daily, 'doses')} target={registration.durationDays * registration.doses} />
                            </Grid>
                            <Grid item xs={12} className={classes.item}>
                                <Progress title="Weekly Progress" variant="weekly" data={data} day={chartDay} setDay={updateChartDay} />
                            </Grid>
                        </Grid>
                    )
                }
                {
                    daysSinceEnd === 0 && day === -1 && dose === -1 && (
                        <Grid container spacing={3} style={{width: '100%', marginTop: '2rem'}}>
                            <Grid item xs={12} sm={6} className={classes.item}>
                                <div style={{display: 'flex', alignItems: 'center', height: '100%'}}>
                                    <Typography variant="h1" style={{fontWeight: '600'}}>
                                        Activity Dashboard
                                    </Typography>
                                </div>
                            </Grid>
                            <Grid item xs={12} sm={6} className={`${classes.item} ${classes.buttonWrapper}`}>
                                <Button disabled className={classes.button} variant="contained" color="primary" onClick={() => setDialogOpen(true)}>
                                    <span style={{padding: '0.5rem 1rem 0.5rem 0.5rem'}}><Add style={{verticalAlign: 'middle'}} /></span>
                                    Record Daily Dose
                                </Button>
                            </Grid>
                            <Grid item xs={12} className={classes.item}>
                                <Grid container spacing={3}>
                                    <Grid item xs={12} sm={6}>
                                        <CustomCard title="Hello!" subTitle="Doses cannot be recorded prior to the trial start date." backgroundColor="rgba(255,187,34,0.38)" />
                                    </Grid>
                                </Grid>
                            </Grid>
                        </Grid>
                    )
                }
                {
                    daysSinceEnd === 0 && day >= 0 && dose >= 0 && (
                        <Grid container className={classes.container} spacing={3} style={{width: '100%', marginTop: '2rem'}}>
                            <Grid item xs={12} sm={6} className={classes.item}>
                                <div style={{display: 'flex', alignItems: 'center', height: '100%'}}>
                                    <Typography variant="h1" style={{fontWeight: '600'}}>
                                        Activity Dashboard
                                    </Typography>
                                </div>
                            </Grid>
                            <Grid item xs={12} sm={6} className={`${classes.item} ${classes.buttonWrapper}`}>
                                <Button className={classes.button} variant="contained" color="primary" onClick={() => setDialogOpen(true)}>
                                    <span style={{padding: '0.5rem 1rem 0.5rem 0.5rem'}}><Add style={{verticalAlign: 'middle'}} /></span>
                                    Record Daily Dose
                                </Button>
                            </Grid>
                            <Grid item xs={12} className={classes.item}>
                                <Grid container spacing={3}>
                                    <Grid item xs={12} sm={6}>
                                        <CustomCard icon={<Sun style={{verticalAlign: 'middle'}} />} title="Hello!" subTitle={greetingSubtitle()} backgroundColor="rgba(255,187,34,0.38)" />
                                    </Grid>
                                    <Grid item xs={12} sm={6}>
                                        {
                                            day === 0 && dose === 0 ? 
                                            (
                                                <CustomCard icon={<UpTrend fontSize="large" style={{verticalAlign: 'middle'}} />} style={{verticalAlign: 'middle'}} title="Let's get started!" subTitle="Record your first dose above." backgroundColor="rgba(101,223,224,0.22)" />
                                            ) :
                                            (
                                                behindBy <= 0 ? 
                                                (
                                                    <CustomCard icon={<UpTrend fontSize="large" style={{verticalAlign: 'middle'}} />} title="You're on track!" subTitle="Keep up the good work!" backgroundColor="rgba(101,223,224,0.22)" />
                                                ) : 
                                                (
                                                    <CustomCard icon={<DownTrend fontSize="large" style={{verticalAlign: 'middle'}} />} title={`You have missed ${behindBy} ${behindBy === 1 ? 'day' : 'days'}.`} subTitle="Time to get back on track." backgroundColor="rgba(255,76,97,0.05)" />
                                                )
                                            )
                                        }
                                    </Grid>
                                </Grid>
                            </Grid>
                            <Grid item xs={12} sm={6} className={classes.item}>
                                <Progress title="Daily Progress" variant="daily" value={dose} target={registration.doses} />
                            </Grid>
                            <Grid item xs={12} sm={6} className={classes.item}>
                                <Progress title={`${registration.durationDays}-Day Progress`} variant="monthly" value={emptyObjects(daily.daily, 'doses')} target={registration.durationDays * registration.doses} />
                            </Grid>
                            <Grid item xs={12} className={classes.item}>
                                <Progress title="Weekly Progress" variant="weekly" data={data} day={chartDay} setDay={updateChartDay} />
                            </Grid>
                        </Grid>
                    )
                }
                <CustomDialog title="Record Daily Dose" onlyToday={daysSinceEnd >= 0 && day === -2 && dose === -2 ? false : true} open={dialogOpen} setOpen={setDialogOpen} record={record} date={selectedDate} setDate={setSelectedDate} theType={theType} setTheType={setTheType} notes={notes} setNotes={setNotes} />
                <SummaryDialog open={summaryOpen} setOpen={setSummaryOpen} />
            </>
            :
            <>
                <div>
                    <Typography variant="h4" style={{fontWeight: '600'}}>
                        Active Participants
                    </Typography>
                    <Typography variant="body2" style={{textAlign: 'left', marginBottom: '1rem'}}>
                        Showing {users.length} of {users.length} participant users
                    </Typography>
                    <Grid container spacing={3}>
                        {
                            users.map((u, i) => (
                                <Grid item sm={12} md={6} key={`activity-user-${i}`}>
                                    <Card style={{boxShadow: '0 .5rem 1rem rgba(0,0,0,.15)'}}>
                                        <CardContent style={{padding: '24px', backgroundColor: '#373B53'}}>
                                            <div>
                                                <Typography gutterBottom variant="h4" component="div" style={{paddingLeft: '24px', paddingRight: '24px', margin: '0', fontWeight: '700', color: '#ffffff'}}>
                                                    <Link className={classes.link} to={`/member/${u.user.username}`}>{u.user.username}</Link>
                                                </Typography>
                                                <Typography gutterBottom variant="body1" component="div" style={{paddingLeft: '24px', paddingRight: '24px', margin: '0', fontWeight: '400', color: '#ffffff'}}>
                                                    Product Type: {`${u.registration.theType[0].toUpperCase()}${u.registration.theType.slice(1).toLowerCase()}`}
                                                </Typography>
                                                <Typography gutterBottom variant="body1" component="div" style={{paddingLeft: '24px', paddingRight: '24px', margin: '0', fontWeight: '400', color: '#ffffff'}}>
                                                    {moment.unix(u.registration.startedAt).isAfter(moment()) ? `Trial Starts: ${moment.unix(u.registration.startedAt).format('MMMM D, YYYY')}` : moment.unix(u.registration.startedAt).add(u.registration.durationDays, 'days').diff(moment(), 'days') <= 0 ? `Trial completed on ${moment.unix(u.registration.startedAt).add(u.registration.durationDays - 1, 'days').format('MMMM D, YYYY')}` : `${moment.unix(u.registration.startedAt).add(u.registration.durationDays, 'days').diff(moment(), 'days')} days remaining in trial`}
                                                </Typography>
                                            </div>
                                        </CardContent>
                                    </Card>
                                </Grid>
                            ))
                        }
                    </Grid>
                </div>
                <div style={{marginTop: '2rem'}}>
                    <Typography variant="h4" style={{fontWeight: '600'}}>
                        Completed Participants
                    </Typography>
                    <Typography variant="body2" style={{textAlign: 'left', marginBottom: '1rem'}}>
                        Showing {completedUsers.length} of {completedUsers.length} participant users
                    </Typography>
                    <Grid container spacing={3}>
                        {
                            completedUsers.map((u, i) => (
                                <Grid item sm={12} md={6} key={`activity-user-${i}`}>
                                    <Card style={{boxShadow: '0 .5rem 1rem rgba(0,0,0,.15)'}}>
                                        <CardContent style={{padding: '24px', backgroundColor: '#373B53'}}>
                                            <div>
                                                <Typography gutterBottom variant="h4" component="div" style={{paddingLeft: '24px', paddingRight: '24px', margin: '0', fontWeight: '700', color: '#ffffff'}}>
                                                    <Link className={classes.link} to={`/member/${u.user.username}`}>{u.user.username}</Link>
                                                </Typography>
                                                <Typography gutterBottom variant="body1" component="div" style={{paddingLeft: '24px', paddingRight: '24px', margin: '0', fontWeight: '400', color: '#ffffff'}}>
                                                    Product Type: {`${u.registration.theType[0].toUpperCase()}${u.registration.theType.slice(1).toLowerCase()}`}
                                                </Typography>
                                                <Typography gutterBottom variant="body1" component="div" style={{paddingLeft: '24px', paddingRight: '24px', margin: '0', fontWeight: '400', color: '#ffffff'}}>
                                                    {moment.unix(u.registration.startedAt).isAfter(moment()) ? `Trial Starts: ${moment.unix(u.registration.startedAt).format('MMMM D, YYYY')}` : moment.unix(u.registration.startedAt).add(u.registration.durationDays, 'days').diff(moment(), 'days') <= 0 ? `Trial completed on ${moment.unix(u.registration.startedAt).add(u.registration.durationDays - 1, 'days').format('MMMM D, YYYY')}` : `${moment.unix(u.registration.startedAt).add(u.registration.durationDays, 'days').diff(moment(), 'days')} days remaining in trial`}
                                                </Typography>
                                            </div>
                                        </CardContent>
                                        {/* <CardContent style={{padding: '24px'}}>
                                            {
                                                u.records && u.records.daily.length > 0 ?
                                                <div className={classes.participantUser}>
                                                    <Typography gutterBottom variant="h6" component="div" style={{paddingLeft: '24px', paddingRight: '24px', margin: '0', fontWeight: '700'}}>
                                                        Recorded Doses
                                                    </Typography>
                                                    <MaterialTable
                                                        columns={[
                                                            {
                                                                title: 'Day',
                                                                field: 'day',
                                                                editable: 'never',
                                                                cellStyle: { paddingLeft: '24px', paddingRight: '24px' },
                                                                headerStyle: { paddingLeft: '24px', paddingRight: '24px' }
                                                            },
                                                            ...u.records.daily[0].doses.map((d, i) => ({
                                                                title: `Dose ${i + 1}`,
                                                                field: `${i}`,
                                                                editable: 'never'
                                                            }))
                                                        ]}
                                                        data={u.data}
                                                        options={{
                                                            search: false,
                                                            pageSize: 10,
                                                            pageSizeOptions: [],
                                                            showFirstLastPageButtons: false,
                                                            showTitle: false,
                                                            toolbar: false
                                                        }}
                                                        style={{boxShadow: 'none'}}
                                                    />
                                                </div>
                                                : null
                                            }
                                        </CardContent> */}
                                        <CardContent style={{padding: '0 24px 24px 24px', backgroundColor: '#373B53'}}>
                                            <div>
                                                <Typography gutterBottom variant="h6" component="div" style={{paddingLeft: '24px', paddingRight: '24px', margin: '0', fontWeight: '700', color: '#ffffff'}}>
                                                    User Post Trial Notes
                                                </Typography>
                                                <Typography gutterBottom variant="body1" component="div" style={{paddingLeft: '24px', paddingRight: '24px', margin: '0', fontWeight: '400', color: '#ffffff'}}>
                                                    { u.note.value ? u.note.value : 'No notes recorded.' }
                                                </Typography>
                                            </div>
                                        </CardContent>
                                    </Card>
                                </Grid>
                            ))
                        }
                    </Grid>
                </div>
            </>
        }
        </>
    );
}
